import { atom } from "recoil";
import { IPointList } from "../api/api";
import {
  createArrayAtom,
  createNumberAtom,
  createStringAtom
} from "./commonAtom";

//// 언어
export const langState = createStringAtom("langState", "English");
export const langCodeState = createStringAtom("langCodeState", "en");

interface LangList {
  lang: string;
  lang_code: string;
}
export const langListState = createArrayAtom<LangList>("langListState", []);

//// hottest game
export const visibleCountState = createNumberAtom("visibleCountState", 10);

export const dataLenState = createNumberAtom("dataLenState", 0);

export const gameStyleState = createStringAtom("gameStyleState", "All");
export const gameTypeState = createStringAtom("gameTypeState", "all");
export const gameNameState = createStringAtom("gameNameState", "all");

export const pagingNumState = createNumberAtom("pagingNumState", 1);

//// video
export const videoIdState = createStringAtom("videoIdState", "");
export const imgIdState = createStringAtom("imgIdState", "");

export const itemDataState = atom<IPointList>({
  key: "itemDataState",
  default: {
    idx: 0,
    game_idx: 0,
    product_amount: 0,
    product_description: "",
    product_name: "",
    product_no: "",
    product_price: 0,
    service_code: "",
    thumbnail_url: "",
    game_title: ""
  }
});

// Mobile_customer
export const mobileListTypeState = createStringAtom(
  "mobileListTypeState",
  "Notice"
);

export const privacyDateState = createNumberAtom("privacyDateState", 0);
export const faqCategoryState = createNumberAtom("faqCategoryState", 0);
interface ICouponText {
  server: string;
  character_name: string;
  coupon_code: string;
}

export const CouponState = atom<ICouponText>({
  key: "CouponState",
  default: {
    server: "Select your server",
    character_name: "",
    coupon_code: ""
  }
});

export const vaildCouponState = atom<ICouponText>({
  key: "vaildCouponState",
  default: {
    server: "",
    character_name: "",
    coupon_code: ""
  }
});
