import { useRecoilValue } from "recoil";
import { isLauncherState } from "atoms/userInfoAtom";
import { redirectURL } from "utils/CommonFunc";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

function GoBtn(props: { url: string; isShort: boolean; siteName: string }) {
  const isLauncher = useRecoilValue(isLauncherState);

  const width440 = useMediaQuery({
    query: "screen and (max-width: 440px)"
  });

  return (
    <Wrap
      isShort={props.isShort}
      className="btn-site"
      onClick={() => redirectURL(props.url, "_self", isLauncher)}
    >
      {props.isShort && width440 ? null : props.siteName}
      <FontAwesomeIcon icon={faUpRightFromSquare} className="icon" />
    </Wrap>
  );
}

export default GoBtn;

const Wrap = styled.button<{ isShort: boolean }>`
  font-size: 12px;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  background: transparent;
  border: 1px solid rgb(255 255 255 / 50%);
  color: rgba(255, 255, 255, 0.7);
  /* margin-right: 16px; */

  .icon {
    margin-left: 5px;
  }

  &:hover {
    background: rgb(255 255 255 / 50%);
    color: rgba(40, 42, 50, 0.7);
  }

  @media screen and (max-width: 440px) {
    .icon {
      margin-left: ${(props) => (props.isShort ? 0 : 5)}px !important;
    }
  }
`;
