import { ILoginData } from "api/authApi";
import { useCookies } from "react-cookie";

export const useSigninSetCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const setCookies = (data: ILoginData["data"]) => {
    // document.cookie = `sso_info_new=${data.sso_info_new}; path=/; domain=.valofe.com`;
    // document.cookie = `sso_info=${data.sso_info}; path=/; domain=.valofe.com`;
    // document.cookie = `gender=${
    //   data.gender.toUpperCase() === "M" || data.gender.toUpperCase() === "F"
    //     ? data.gender
    //     : "+"
    // }; path=/; domain=.valofe.com`;
    // document.cookie = `email=${data.email}; path=/; domain=.valofe.com`;
    // setCookie("birthday", data.birthday, {
    //   path: "/",
    //   domain: ".valofe.com"
    // });
    // setCookie("service_code", data.service_code, {
    //   path: "/",
    //   domain: ".valofe.com"
    // });
    // setCookie("userid", data.user_id, {
    //   path: "/",
    //   domain: ".valofe.com"
    // });
    // setCookie("SSOKey", data.SSOKey, {
    //   path: "/",
    //   domain: ".valofe.com"
    // });
    // setCookie("country", data.country, {
    //   path: "/",
    //   domain: ".valofe.com"
    // });
    // setCookie("auth", data.auth, {
    //   path: "/",
    //   domain: ".valofe.com"
    // });
    // setCookie("member_type", data.member_type, {
    //   path: "/",
    //   domain: ".valofe.com"
    // });
    // setCookie("check_email", data.check_email, {
    //   path: "/",
    //   domain: ".valofe.com"
    // });
    // setCookie("user_profile_key", data.user_profile_key, {
    //   path: "/",
    //   domain: ".valofe.com"
    // });
    // setCookie(
    //   process.env.REACT_APP_COOKIES_EXPIRED as string,
    //   data.expired_at,
    //   {
    //     path: "/",
    //     domain: ".valofe.com"
    //   }
    // );
  };

  return setCookies;
};
