import { t } from "i18next";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { Dialog } from "./DialogStyle";

interface CustomAlertProps {
  message: string;
  onClickOK: () => void;
  onClickCancel: () => void;
}

const CustomConfirm = ({
  message,
  onClickOK,
  onClickCancel
}: CustomAlertProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleCancel = () => {
    setShowModal(false);
    setTimeout(() => onClickCancel(), 1);
  };

  const handleOk = () => {
    setShowModal(false);
    setTimeout(() => onClickOK(), 1);
  };

  useEffect(() => {
    setTimeout(() => setShowModal(true), 1);
  }, []);
  return (
    <Dialog.Container showModal={showModal}>
      <div className="shadow" />
      <Dialog.Content>
        <Dialog.Message>{message}</Dialog.Message>
        <Dialog.Buttons>
          <Dialog.Button onClick={handleCancel} color="b">
            {t("cancel")}
          </Dialog.Button>
          <Dialog.Button onClick={handleOk} color="p">
            {t("ok")}
          </Dialog.Button>
        </Dialog.Buttons>
      </Dialog.Content>
    </Dialog.Container>
  );
};

const customConfirm = (message: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const handleOk = () => {
      const modalRoot = document.getElementById("modal-alert-portal-wrapper");
      if (modalRoot) modalRoot.remove();

      resolve(true);
    };
    const handleCancel = () => {
      const modalRoot = document.getElementById("modal-alert-portal-wrapper");
      if (modalRoot) modalRoot.remove();

      resolve(false);
    };

    if (typeof window !== "undefined") {
      const subDiv = document.createElement("div");
      subDiv.id = "modal-alert-portal-wrapper";
      document.body.appendChild(subDiv);

      const root = createRoot(subDiv);
      root.render(
        <CustomConfirm
          message={message}
          onClickOK={handleOk}
          onClickCancel={handleCancel}
        />
      );
    }
  });
};

export default customConfirm;
