import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useCookies, withCookies } from "react-cookie";
import "languages/i18n";
import md5 from "md5";
import ReactGA from "react-ga4";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import "App.scss";
import { redirectURL, Service_GL } from "utils/CommonFunc";
import {
  emailAuthStatus,
  headersState,
  isLauncherState,
  juminState,
  isLogInState,
  ssoinfoState,
  userBirthState,
  userEmailState,
  userIdState,
  userNicknameState,
  userProfileImgState,
  userProfileKeyState,
  isMobileAppState,
  loginLoadingState
} from "atoms/userInfoAtom";
import { initGA } from "utils/tracking";
import TagManager from "react-gtm-module";
import { GET_MEMBERINFO_V, IMember } from "api/api";
import { VERIFY_KEY } from "utils/config/Config";
import PageRoutes from "routers/PageRoutes";
import ScrollTop from "utils/ScrollTop";
import ResetInfo from "utils/ResetInfo";
import { langCodeState, langState } from "atoms/contentsAtom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import airbridge from "airbridge-web-sdk-loader";
import { getAirbridgeSendEvent } from "api/libraryApi";
import { useLogout } from "hooks/useLogout";
import { refreshTokenApi } from "api/authApi";
import WrapLoading from "components/loading/WrapLoading";

function App() {
  const [isLogin, setIsLogin] = useRecoilState(isLogInState);
  const setUserID = useSetRecoilState(userIdState);
  const setSSOInfo = useSetRecoilState(ssoinfoState);
  const setJuminInfo = useSetRecoilState(juminState);
  const setUserBirth = useSetRecoilState(userBirthState);
  const setUserEmail = useSetRecoilState(userEmailState);
  const setLang = useSetRecoilState(langState);
  const setIsLauncher = useSetRecoilState(isLauncherState);
  const setUserNickName = useSetRecoilState(userNicknameState);
  const setUserProfileKey = useSetRecoilState(userProfileKeyState);
  const setEmailAuth = useSetRecoilState(emailAuthStatus);
  const setUserProfileImg = useSetRecoilState(userProfileImgState);
  const setIsMobileApp = useSetRecoilState(isMobileAppState);
  const deviceType = window.navigator.userAgent.split("::")[1];
  const [langCode, setLangCode] = useRecoilState(langCodeState);
  const [cookies, setCookie, removeCookie] = useCookies();
  const { i18n } = useTranslation();
  const headers = useRecoilValue(headersState);
  const logout = useLogout();
  const [loginLoading, setLoginLoading] = useRecoilState(loginLoadingState);

  axios.defaults.withCredentials = true;

  // 런처 여부
  useEffect(() => {
    if (deviceType === "vfun-launcher") {
      setIsLauncher(true);
    } else if (deviceType === "vfun-app") {
      setIsMobileApp(true);

      const vfunGnb = document.getElementById("vfun-common-gnb");
      if (vfunGnb) {
        vfunGnb.remove();
      }
    }
  }, [deviceType]);

  // google GA
  useEffect(() => {
    if (
      window.location.hostname.includes(".valofe.com") ||
      window.location.hostname.includes("localhost")
    ) {
      // valofe_process();
      //2023. 7월 1일부터 google GA가 UA 속성 지원하지 않음
      initGA(Service_GL ? "G-G9XDFFQ5PJ" : "G-1T67BP0JE8");
      ReactGA.send("pageview"); // 페이지 뷰가 다른 이벤트 추척보다 선행되어야 함
      ReactGA.event({
        category: "User",
        action: "Created an Account", //계정생성 추적
        label: Service_GL ? "VFUN Lounge 회원 가입" : "VFUN KR 회원 가입" // + 서비스 코드가 들어가면 더 좋겠습니다.
      });

      const tagManagerArgs = {
        gtmId: "GTM-W9ZFSCR"
      };
      TagManager.initialize(tagManagerArgs);

      // 서비스 코드 쿠키
      if (
        cookies["service_code"] === undefined ||
        cookies["service_code"] === null
      ) {
        if (Service_GL) {
          setCookie("service_code", "vfun", {
            path: "/",
            domain: ".valofe.com"
          });
        }
      }
    }
  }, []);

  // airbridge
  useEffect(() => {
    window.vFunAirbridge_Log = function (
      category: string,
      serviceCode: string
    ) {
      airbridge.fetchResource(function (res) {
        const client_id = res.browser.clientID;
        const user_agent = encodeURIComponent(res.browser.userAgent);
        const short_id = res.attributionData.shortID;
        const channel = res.attributionData.trackingData.channel;

        getAirbridgeSendEvent(
          serviceCode,
          langCode,
          client_id,
          user_agent,
          short_id,
          channel,
          category,
          headers
        );
      });
    };
  }, []);

  const getRetValue = () => {
    try {
      const urlObj = new URL(window.location.href); // URL 객체 생성
      return urlObj.searchParams.get("ret"); // 'ret' 파라미터 값 추출
    } catch (error) {
      console.error("유효하지 않은 URL입니다.", error);
      return null;
    }
  };

  const memberInfoProcess = async (userid: string, birthday: string) => {
    setLoginLoading(true);
    try {
      let res: any = await GET_MEMBERINFO_V({
        Ssoinfo: encodeURIComponent(""),
        Userid: userid,
        Userbirth: birthday
      });

      if (res.result === 1) {
        const userData: IMember = res;
        setUserID(userData.data.user_id);
        setSSOInfo(userData.sso_info);
        setUserBirth(userData.data.user_birthday);
        setUserEmail(userData.data.email);
        setUserNickName(userData.data.nickName || "");
        setUserProfileKey(userData.data.user_profile_key);
        setUserProfileImg(userData.data.profile_image_url || "");
        setIsLogin(true);
        setEmailAuth(userData.email_auth_status);
      } else if (res.result === 4010) {
        axios.post(refreshTokenApi).then((res) => {
          if (res.data.result === 1) {
            setCookie(
              process.env.REACT_APP_COOKIES_EXPIRED as string,
              res.data.data.expired_at,
              {
                path: "/",
                domain: ".valofe.com"
              }
            );
            // window.location.reload();
          }
        });
      } else {
        logout();
      }
    } catch (error) {
      console.log(error, "login");
    } finally {
      setLoginLoading(false);
      window.localStorage.removeItem("requiredRet");
    }
  };

  const valofe_process = useCallback(() => {
    if (Service_GL) {
      // // 글로벌
      let cookies_ssoinfo = "";
      let cookies_userid = cookies["userid"];
      let cookies_birthday = cookies["birthday"];
      let expired_at = cookies[process.env.REACT_APP_COOKIES_EXPIRED as string];
      if (expired_at !== undefined) {
        memberInfoProcess(cookies_userid, cookies_birthday);
      }
    } else {
      // 한국
      let cookies_goonzu = cookies["goonzu"];
      if (cookies_goonzu === undefined) {
        setIsLogin(false);
      } else {
        let cookies_info = cookies_goonzu.split("&");
        if (cookies_info.length > 0) {
          let param: any = [];
          for (let i = 0; i < cookies_info.length; i++) {
            let key = cookies_info[i].split("=")[0];
            let value = cookies_info[i].split("=")[1];
            param[key] = value;
          }
          let jumin = cookies["jumin"];
          let ssoinfo =
            cookies[process.env.REACT_APP_SSO_INFO_COOKIE_NAME as string];
          let user_id = decodeURI(param["userid"]);
          let cookies_string = user_id + jumin + VERIFY_KEY;
          if (md5(cookies_string) === ssoinfo) {
            setUserID(user_id);
            setSSOInfo(ssoinfo);
            setJuminInfo(jumin);
            setIsLogin(true);
          } else {
            setIsLogin(false);
          }
        } else {
          setIsLogin(false);
        }
      }
    }
  }, [cookies[process.env.REACT_APP_COOKIES_EXPIRED as string]]);

  useEffect(() => {
    valofe_process();
  }, [valofe_process]);

  useEffect(() => {
    const handleLoginStatusChange = (event: CustomEvent<boolean>) => {
      const gnbLoginStatus = event.detail;

      let Userid = cookies["userid"];
      let Userbirth = cookies["birthday"];
      let expired_at = cookies[process.env.REACT_APP_COOKIES_EXPIRED as string];

      if (
        gnbLoginStatus &&
        !(deviceType === "vfun-app" || deviceType === "vfun-launcher")
      ) {
        memberInfoProcess(Userid, Userbirth);
      }

      if (gnbLoginStatus) {
        if (
          getRetValue() !== "" &&
          getRetValue() !== null &&
          !(deviceType === "vfun-launcher" || deviceType === "vfun-app")
        ) {
          redirectURL(
            getRetValue() as string,
            "_self",
            deviceType === "vfun-launcher"
          );
        }
      }
    };

    window.addEventListener(
      "loginValue",
      handleLoginStatusChange as EventListener
    );
    return () =>
      window.removeEventListener(
        "loginValue",
        handleLoginStatusChange as EventListener
      );
  }, [isLogin]);

  useEffect(() => {
    setLangCode(cookies["locale"]);
    i18n.changeLanguage(cookies["locale"]);
    const handleLangStatusChange = (event: CustomEvent<string>) => {
      const lang = event.detail;
      setLangCode(lang);
      i18n.changeLanguage(lang);
    };

    window.addEventListener(
      "languageValue",
      handleLangStatusChange as EventListener
    );
    return () =>
      window.removeEventListener(
        "languageValue",
        handleLangStatusChange as EventListener
      );
  }, []);

  return (
    <Router>
      <ScrollTop />
      <ResetInfo />
      {loginLoading ? <WrapLoading /> : <PageRoutes />}
    </Router>
  );
}

export default withCookies(App);
