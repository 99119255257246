// vfun lounge
export const NOTFOUND = "/notfound";
export const COMMUNITY_PAGE_CODE = "/page/:service_code/";
export const COMMUNITY_PAGE_CODE_CATEGORY = ":title";
export const COMMUNITY_PAGE_CODE_LANG = "/page/:service_code/:title/:lang";
export const COMMUNITY_PAGE_CODE_IDX = ":idx";

//// vfun
export const MAIN_HOME_PAGE_CODE = "/home";
export const ALL_GAME_PAGE_CODE = "/game/all_games";
export const POPULAR_GAME_PAGE_CODE = "game/popular_games";
export const LIBRARY_PAGE_CODE = "/library?";
export const DOWNLOAD_PAGE_CODE = "/game/download";
export const CASINO_GAME_CODE = "/game/play/realcasino";

// stamp
export const STAMP_PAGE_CODE = "/stamp/mission?";
export const POINT_SHOP_PAGE_CODE = "/stamp/shop?";
export const MY_STAMP_PAGE_CODE = "/stamp/my_stamp";
export const HISTORY_PAGE_CODE = "/stamp/history";

// community
export const EVENT_PAGE_CODE = "/community/event";
export const NEWS_PAGE_CODE = "/community/news";
export const MOBILE_LIST_PAGE_CODE = "/Mobile_customer/mobile_notice_list?";
export const MOBILE_DETAIL_PAGE_CODE =
  "/Mobile_customer/mobile_notice_detail_view?";

// membership
export const SIGN_UP_PAGE_CODE = "/membership/signup?";
export const VFUN_SIGN_UP_PAGE_CODE = "/membership/sns_signup_step_vfun?";
export const SIGN_IN_PAGE_CODE = "/membership/signin";
export const FIND_ID_PAGE_CODE = "/membership/find_id";
export const FIND_PW_PAGE_CODE = "/membership/find_pw";
export const MY_INFO_CODE = "/membership/myinfo?";
export const EMAIL_VERIFICATION_CODE = "/membership/sns_email_auth_confirm";
export const EMAIL_CHANGE_PAGE_CODE = "/membership/sns_email_chg";
export const PASSWORD_CHANGE_PAGE_CODE = "/membership/change_pw?";
export const FIND_ID_COMPLETE_CODE = "/membership/find_id_complete?";
export const LEAVE_VFUN_CODE = "/membership/leave_vfun";
export const LEAVE_COMPLETE = "/membership/leave_complete";
export const SNS_LOGIN_CODE = "/membership/sns_login";

// support
export const VFUN_SUPPORT_CODE = "/customer/support?";
export const INQUIRY_LIST_CODE = "/customer/inquiry_list?";
export const MEMBER_INQUIRY_CODE = "/customer/inquiry?";
export const NON_MEMBER_INQUIRY_CODE = "/customer/inquiry_not_login?";
export const VFUN_TERMS_CODE = "/customer/g_pc_terms";
export const VFUN_PRIVACY_CODE = "/customer/g_pc_privacy";
export const INQUIRY_VIEW_CODE = "/customer/inquiry_view?";
export const MOBILE_FAQ_PAGE_CODE = "/Mobile_customer/faq?";
export const MOBILE_FAQ_HERO_PAGE_CODE = "/Mobile_customer/faq_hero?";
export const MOBILE_INQUIRY_CODE = "/Mobile_customer/inquiry";
export const MOBILE_TERMS_CODE = "/customer/g_mobile_terms";
export const MOBILE_PRIVACY_CODE = "/customer/g_mobile_privacy";
export const JP_TERMS_LAW_CODE = "/customer/commercial_law";
export const JP_TERMS_ACT_CODE = "/customer/payment_act";

// otp
export const GOOGLE_OTP_APPLY_CODE = "/otp/apply";
export const GOOGLE_OTP_INSTALL_CODE = "/otp/install";
export const GOOGLE_OTP_SETTING_CODE = "/otp/setting";
export const GOOGLE_OTP_APPLYVERIFY_CODE = "/otp/applyVerify";
export const GOOGLE_OTP_COMPLETE_CODE = "/otp/complete";
export const GOOGLE_OTP_LEAVE_CODE = "/otp/leave";
export const GOOGLE_OTP_LEAVE_CHECK_CODE = "/otp/leave_check";
export const GOOGLE_OTP_LEAVE_EMAIL_CODE = "/otp/leave_email";
export const GOOGLE_OTP_LEAVEVERIFY_CODE = "/otp/leaveVerify";
export const GOOGLE_OTP_LEAVE_COMPLETE_CODE = "/otp/leave_complete";

// 인증 확인 페이지
export const SIGN_UP_COMPLETE_CODE = "/membership/member_certification?";
export const MY_INFO_CHECK_EMAIL_CODE = "/membership/myinfo_check_email?";
export const VFUN_WITH_DRAW_CODE = "/membership/withdraw?";
export const SNS_CHANGE_EMAIL = "/membership/sns_change_email?";
export const REDIRECT_PAGE = "/redirect?";

// 런처 소셜 로그인
export const LAUNCHER_SIGN_IN_CODE = "/membership/launcher_signin?";
export const LAUNCHER_SIGN_IN_FACEBOOK_CODE =
  "/membership/LauncherFacebooklogin";
export const LAUNCHER_SIGN_IN_GOOGLE_CODE = "/membership/LauncherGooglelogin";
export const LAUNCHER_SIGN_IN_APPLE_CODE = "/membership/LauncherApplelogin";
