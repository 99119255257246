import { useCookies } from "react-cookie";
import {
  ILauncherSnsLogin,
  SnsSignupCheckApi,
  launcherSignInProcApi
} from "api/authApi";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isLauncherState } from "atoms/userInfoAtom";
import { wrapLoadingState } from "atoms/uiAtom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { redirectURL } from "utils/CommonFunc";

function LauncherApplelogin() {
  const [cookies] = useCookies();
  const userInfo: ILauncherSnsLogin = cookies["sns_info"];
  const isLauncher = useRecoilValue(isLauncherState);
  const setIsLoading = useSetRecoilState(wrapLoadingState);
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo !== undefined || userInfo !== null) {
      // vfun 아이디가 비어있으면 신규 회원, 있으면 기존 회원
      const signUpCheck = async () => {
        setIsLoading(true);
        try {
          let res = await axios.get(
            SnsSignupCheckApi("Apple", userInfo.real_id)
          );
          if (res.data.result === 1) {
            setIsLoading(false);
            navigate("/membership/sns_login", {
              state: {
                type: "Apple",
                birthday: userInfo.birthday || "",
                email: userInfo.email,
                first_name: userInfo.first_name || "",
                id: userInfo.real_id,
                last_name: userInfo.last_name || ""
              }
            });
            // 이미 아이디가 있을 경우
          } else if (res.data.result === -98) {
            try {
              let res = await axios.post(launcherSignInProcApi, {
                user_id: userInfo.vfun_id
              });

              if (res.data.result === 1) {
                redirectURL(
                  res.data.launcher_redirect_url,
                  "_self",
                  isLauncher
                );
              }
            } catch (error) {
              console.log("aa l", error);
            }
          }
        } catch (error) {
          console.log("aa l signUp", error);
        }
        setIsLoading(false);
      };

      signUpCheck();
    }
  }, [userInfo]);

  return null;
}

export default LauncherApplelogin;
