import styled from "styled-components";

export const SlideArrow = styled.section`
  background: rgb(40, 42, 46);

  .none {
    display: none;
  }

  .single {
    margin: 0;
    width: 100%;
    position: relative;
  }

  .double {
    position: relative;
    display: inline-block;
    margin-right: 0.563rem;
    width: calc(50% - 0.563rem);

    &.comming {
      margin-right: 0px;
      margin-left: 0.563rem;
    }
  }

  .comming {
    .wrapper {
      background: rgba(0, 0, 0, 0.15);
    }
    .game-info-area {
      p {
        color: rgba(60, 159, 253, 0.85);
      }
    }
  }

  .recommend {
    .wrapper {
      background: rgba(0, 0, 0, 0.15);
    }
    .game-info-area {
      p {
        color: #ffbd00;
      }
    }
  }

  .slick-slider {
    position: static;
  }

  .slick-list {
    border-radius: 10px;
    overflow: hidden;
  }

  .slick-dots li {
    display: none;
    width: auto;
    margin: 0;
  }

  .slick-dots li.slick-active {
    display: inline-block;
  }

  .slick-dots {
    position: absolute;
    top: 10px;
    text-align: end;

    width: 70px;
    height: 34px;
    right: 0;

    .page-num {
      display: inline-block;
      font-size: 12px;
      letter-spacing: 2px;
      font-weight: 400;
      vertical-align: super;
    }

    .arrows {
      clear: both;
      display: inline-block;
      margin-left: 6px;
      vertical-align: baseline;

      .prev,
      .next {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 14px;
        height: 14px;
        line-height: 0;
        border: 1px solid #d1d1d1;
        background-color: #eee;
        color: #4f4f4f;
        cursor: pointer;
        transition: all 0.2s linear;
        background-image: none;

        &:hover {
          background-color: rgba(60, 159, 253, 0.85);
          border: 1px solid #3c9ffd;
          color: #fff;
        }

        svg {
          height: 100%;
          vertical-align: baseline;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    h1 {
      font-size: 1.875rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .mg {
      margin-top: 4rem;
    }
  }

  @media screen and (max-width: 940px) {
    .recommend,
    .comming {
      display: block;
      width: 100%;
      margin-right: 0;
      margin-left: 0 !important;

      &.none {
        display: none;
      }
    }

    .comming {
      margin-top: 1.5rem;
    }
  }

  @media screen and (max-width: 414px) {
    .title-area {
      padding-bottom: 1rem;
    }

    .slick-dots {
      top: 24px;
    }

    .game-info-area p {
      font-size: 0.75rem;
    }
  }

  @media screen and (max-width: 375px) {
    h1 {
      font-size: 1.75rem;
      text-align: center;
    }
  }

  @media screen and (max-width: 360px) {
    h1 {
      font-size: 1.5rem;
    }
    .game-info-area p {
      max-height: 100%;
    }
  }
`;
