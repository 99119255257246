import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { langCodeState, mobileListTypeState } from "atoms/contentsAtom";
import { useInfiniteQuery } from "react-query";
import { IPostData, getMobileList } from "api/libraryApi";
import axios from "axios";
import { dateFormType1 } from "utils/CommonFunc";
import MobileCommunityWrap from "components/common/Wrap/MobileCommunityWrap";

function MobileList() {
  const [searchParams] = useSearchParams();
  const service_code = searchParams.get("service_code");
  const market_idx = searchParams.get("market_idx");
  const listType = useRecoilValue(mobileListTypeState);
  const langCode = useRecoilValue(langCodeState);

  const useInfiniteScrollQuery = () => {
    const getPageBoard = async ({ pageParam = 1 }) => {
      const res = await axios.get(
        getMobileList(service_code || "", listType, langCode, pageParam)
      );

      return {
        result: res.data.result,
        boardpage: res.data.result === 1 ? res.data.data.data : "",
        currentpage: res.data.result === 1 ? res.data.data.current_page : 0,
        total: res.data.result === 1 ? res.data.data.total : 0,
        isLast:
          res.data.result === 1
            ? res.data.data.current_page === res.data.data.last_page
              ? true
              : false
            : false
      };
    };

    const {
      data: getBoard,
      fetchNextPage: getNextPage,
      isSuccess: getBoardIsSuccess,
      hasNextPage: getNextPageIsPossible
    } = useInfiniteQuery(["mobile_list", langCode, listType], getPageBoard, {
      getNextPageParam: (lastPage, pages) => {
        // lastPage와 pages는 콜백함수에서 리턴한 값을 의미한다!!
        // lastPage: 직전에 반환된 리턴값, pages: 여태 받아온 전체 페이지
        if (!lastPage.isLast) {
          return lastPage.currentpage + 1;
        }
        // 마지막 페이지면 undefined가 리턴되어서 hasNextPage는 false가 됨!
        return undefined;
      }
    });

    return { getBoard, getNextPage, getBoardIsSuccess, getNextPageIsPossible };
  };

  const { getBoard, getNextPage, getBoardIsSuccess, getNextPageIsPossible } =
    useInfiniteScrollQuery();

  const onClickNext = () => {
    if (getNextPageIsPossible) {
      getNextPage();
    }
  };

  return (
    <MobileCommunityWrap>
      <ul className="list-group">
        {getBoardIsSuccess ? (
          getBoard?.pages[0].result === 1 ? (
            <>
              {getBoard.pages.map((pageData) =>
                pageData.boardpage.map((i: IPostData) => (
                  // 게시글
                  <li className="list-group-item" key={i.idx}>
                    <Link
                      to={`/Mobile_customer/mobile_notice_detail_view?idx=${i.idx}&service_code=${service_code}&market_idx=${market_idx}`}
                    >
                      <div className="pull-right board-date text-warning">
                        <small>{dateFormType1(i.created_at)}</small>
                      </div>
                      <span className="bolt-label bolt-label-default">
                        {listType.toUpperCase()}
                      </span>
                      {i.title}
                    </Link>
                  </li>
                ))
              )}
              {/* 버튼 */}
              {getNextPageIsPossible ? (
                <li className="list-group-item more-btn" onClick={onClickNext}>
                  <button type="button">more</button>
                </li>
              ) : (
                <li className="list-group-item more-btn">
                  <div>Last Contents</div>
                </li>
              )}
            </>
          ) : null
        ) : null}
      </ul>
    </MobileCommunityWrap>
  );
}

export default MobileList;
