import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { VFUN_IMAGE_DNS } from "utils/config/Config";

interface IProps {
  point: string;
  num: string;
}

function Shortcuts(props: IProps) {
  const { point, num } = props;
  const { t } = useTranslation();
  return (
    <Link className="btn-window" to={num} smooth={true}>
      <div className="cont">
        <img
          src={
            VFUN_IMAGE_DNS +
            "/web/vfun/images/vfun_lounge/images/icon-windows.png"
          }
          alt="icon-windows"
        />
        <span className="btn-text">
          <div>{t(`Windows 10 ${point}`)}</div>
          <div>{t("사용자 바로가기")}</div>
        </span>
        <img
          src={
            VFUN_IMAGE_DNS +
            "/web/vfun/images/vfun_lounge/images/icon-angle-right.png"
          }
          alt="icon-angle-right"
        />
      </div>
    </Link>
  );
}

export default Shortcuts;
