import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Paging from "components/common/Paging";
import { useQuery } from "react-query";
import { IFaqLists, getFAQListApi } from "api/customer";
import {
  faqCategoryState,
  langCodeState,
  pagingNumState
} from "atoms/contentsAtom";
import parse from "html-react-parser";
import { t } from "i18next";

function FAQ(props: { faqIdx?: number; row?: string }) {
  const pageNum = useRecoilValue(pagingNumState);
  const langCode = useRecoilValue(langCodeState);
  const [searchParams] = useSearchParams();
  const service_code = searchParams.get("service_code");
  const faq_category = useRecoilValue(faqCategoryState);
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const { isLoading, data } = useQuery<IFaqLists>(
    ["faqList", pageNum, langCode, service_code, faq_category, props.faqIdx],
    () => getFAQListApi(pageNum, langCode, service_code || "vfun", faq_category)
  );

  useEffect(() => {
    setActiveIndex(null);
  }, [searchParams]);

  const onClickType = (idx: number) => {
    setActiveIndex(idx);
  };

  return (
    <section className={`sec2 faq ${props.row}`}>
      <h1>
        <span className="color-blue">VFUN</span>&nbsp;
        <span className="color-grey3">FAQ</span>
      </h1>
      <ul className="list">
        {!isLoading ? (
          data?.result === 1 ? (
            data.data.faq_list.map((i, idx) => (
              <li
                key={i.idx}
                onClick={() => onClickType(idx)}
                className={activeIndex === idx ? "on" : ""}
              >
                <div className="question">
                  <div className="pull-left">
                    <h3 className="ellipsis">
                      <Link to={window.location.href}>Q. {i.title}</Link>
                    </h3>
                  </div>
                  <div className="pull-right">
                    {activeIndex === idx ? (
                      <FontAwesomeIcon icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon icon={faAngleDown} />
                    )}
                  </div>
                </div>
                {activeIndex === idx ? (
                  <div className="answer">{parse(i.contents)}</div>
                ) : null}
              </li>
            ))
          ) : (
            <li className="no-list">
              <div className="question">
                <h3 className="ellipsis">{t("there_is_no_post")}</h3>
              </div>
            </li>
          )
        ) : null}
      </ul>
      {!isLoading ? (
        data?.result === 1 ? (
          <Paging current={Number(data.data.page)} total={data.data.end_page} />
        ) : null
      ) : null}
    </section>
  );
}

export default FAQ;
