import { useRecoilValue } from "recoil";
import { langCodeState } from "atoms/contentsAtom";
import { IOtpList, getOtpGameList } from "api/otpApi";
import { useQuery } from "react-query";

function GameList() {
  const langCode = useRecoilValue(langCodeState);
  const { isLoading, data } = useQuery<IOtpList>(["otpgamelist"], () =>
    getOtpGameList(langCode)
  );

  return !isLoading ? (
    <ul id="games" className="box clearfix">
      {/* 리스트 7개 단위로 보임  */}
      {data?.result === 1
        ? data.msg.map((i) => (
            <li className="game-list" key={i.game_title}>
              <img
                className="circle"
                src={i.thumbnail_url}
                alt={i.game_title}
              />
              <dl className="info">
                <dt className="tit ellipsis">{i.game_title}</dt>
                {/* <dd className="lang"></dd> */}
              </dl>
            </li>
          ))
        : null}
    </ul>
  ) : null;
}

export default GameList;
