import { useQuery } from "react-query";
import { IMobileDetail, getMobileDetail } from "api/libraryApi";
import { headersState } from "atoms/userInfoAtom";
import { useRecoilValue } from "recoil";
import { mobileListTypeState } from "atoms/contentsAtom";
import { Link, useSearchParams } from "react-router-dom";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import MobileCommunityWrap from "components/common/Wrap/MobileCommunityWrap";

function MobileDetail() {
  const headers = useRecoilValue(headersState);
  const [searchParams] = useSearchParams();
  const service_code = searchParams.get("service_code");
  const idx = searchParams.get("idx");
  const market_idx = searchParams.get("market_idx");
  const listType = useRecoilValue(mobileListTypeState);

  const { isLoading, data } = useQuery<IMobileDetail>(["detailContent"], () =>
    getMobileDetail(service_code || "", listType, idx || "", headers)
  );

  return (
    <MobileCommunityWrap>
      {!isLoading ? (
        data?.result === 1 ? (
          <div className="view">
            <span className="bolt-label bolt-label-blue">{listType}</span>
            <br />
            {/* 내용 */}
            <h1 className="view-title">{data.data.title}</h1>
            <div className="view-content">
              {parse(data.data.content)}{" "}
              <div className="board-date">
                <small>
                  <small>{data.data.user_id}</small>
                  <small>{data.data.created_at}</small>
                </small>
              </div>
            </div>
          </div>
        ) : null
      ) : null}
      <Link
        to={`/Mobile_customer/mobile_notice_list?service_code=${service_code}&market_idx=${market_idx}`}
        className="mobile_prev"
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </Link>
    </MobileCommunityWrap>
  );
}

export default MobileDetail;
