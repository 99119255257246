import React, { useRef } from "react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { redirectURL } from "utils/CommonFunc";
import GameTab from "components/common/GameTab";
import { IDetailData, INewGames } from "api/mainApi";
import { isLauncherState } from "atoms/userInfoAtom";
import { useRecoilValue } from "recoil";

interface ICommingProps {
  data: INewGames;
}

function CommingGame(props: ICommingProps) {
  const { data } = props;
  const isLauncher = useRecoilValue(isLauncherState);
  const totalCNum = data?.result === 1 ? data.data.commingsoon_count : 1;

  const commingRef = useRef<any>(null);
  const onClickPrevcomming = () => commingRef.current.slickPrev();
  const onClickNextcomming = () => commingRef.current.slickNext();

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    speed: 500,
    draggable: false,
    arrows: false,
    dots: true,
    appendDots: function (dots: React.ReactNode): JSX.Element {
      // dots 겉을 감싸는
      return (
        <div className="arrow-wrap">
          {dots}
          <div className="arrows">
            <span className="prev" onClick={onClickPrevcomming}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </span>
            <span className="next" onClick={onClickNextcomming}>
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </div>
        </div>
      );
    },
    customPaging: function (i: number): JSX.Element {
      // dots 안의 값이고
      return (
        <span className="page-num">
          {i + 1}/{totalCNum}
        </span>
      );
    }
  };

  return (
    <div
      className={`${
        data.data.recommend_count !== 0 && data.data.commingsoon_count !== 0
          ? "double"
          : data.data.recommend_count === 0 && data.data.commingsoon_count !== 0
          ? "single"
          : "none"
      } comming`}
    >
      <div className="title-area">
        <h1>Coming Soon</h1>
      </div>
      <Slider {...settings} ref={commingRef}>
        {data.data.commingsoon_data.map((i: IDetailData) => (
          <div key={i.idx}>
            <Link
              to="#"
              onClick={() => redirectURL(i.link_url, i.target, isLauncher)}
            >
              <GameTab
                gameName={i.title}
                shortDesc={i.desc}
                genre={i.genre}
                gameImg={i.thumbnail_url}
                pre={i.pre_creation}
              />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CommingGame;
