import { useState } from "react";
import PopUpWrap from "./PopUpWrap";
import { useRecoilState, useRecoilValue } from "recoil";
import { snsQrPopUpState, snsQrState } from "atoms/uiAtom";
import parse from "html-react-parser";
import PurpleBtn from "../common/components/PurpleBtn";
import { redirectURL } from "utils/CommonFunc";

function SnsQRPopUp() {
  const [snsQrPop, setSnsQrPop] = useRecoilState(snsQrPopUpState);
  const snsQr = useRecoilValue(snsQrState);

  const onClickBtn = () => {
    redirectURL(snsQr.url, "_blank", false);
  };

  return (
    <PopUpWrap state={snsQrPop} setState={setSnsQrPop} type="snsQr">
      <img src={`data:image/png;base64,${snsQr.qr}`} />
      <PurpleBtn onClick={onClickBtn} title="Link" />
    </PopUpWrap>
  );
}

export default SnsQRPopUp;
