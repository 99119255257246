import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { Link as ScrollMove } from "react-scroll";
import { t } from "i18next";

function AllGameScroll() {
  return (
    <ToAllGame className="row-w clearfix">
      <ScrollMove
        smooth={true}
        offset={-60}
        to="all-game"
        className="go-to-section smooth-goto"
      >
        <FontAwesomeIcon
          icon={faAngleDoubleDown}
          className="faAngleDoubleDown"
        />
        {t("all_games")}
      </ScrollMove>
    </ToAllGame>
  );
}

export default AllGameScroll;

const ToAllGame = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  margin: 1.375rem auto 0;
  text-align: end;
  z-index: 1;

  .go-to-section {
    color: rgba(60, 159, 253, 0.85);
    font-size: 1.5625rem;
    font-weight: 400;
    letter-spacing: -0.0625rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: rgba(60, 159, 253, 0.65);
    }
  }

  .faAngleDoubleDown {
    padding-right: 5px;
  }

  @media screen and (max-width: 1200px) {
    .go-to-section {
      font-size: 1.25rem;
    }
  }
  @media screen and (max-width: 1024px) {
    margin: 1.5rem auto 0;
  }
`;
