import CustomerWrap from "components/common/Wrap/CustomerWrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

function JpActTerms() {
  return (
    <CustomerWrap background="#33363f">
      <div id="container">
        <Wrap className="policy">
          <div id="ver1" className="contents">
            <h1>資金決済法に基づく表示</h1>
            <br />
            <br />● 発行事業者 <br />
            株式会社G・O・P <br />
            <br />● 前払決済手段の名称
            <br />
            VFUNで提供する前払手段の名称は【Gコイン】です。 <br />
            <br />● 支払可能金額など
            <br />
            対象となる先払いの支払い手段であるGコインの上限はありません。
            <br />
            ただし、不正決済防止システムにより、アカウント内でのお支払いが停止になる場合がございます。
            <br />
            <br />
            ● 支払可能金額等
            <br />
            18歳未満 : 1ヶ月5000円まで
            <br />
            18歳以上 : 無制限
            <br />
            <br />
            ● Payable amount, etc.
            <br />
            Under 18 years old: up to 5,000 yen per month
            <br />
            Over 18 years old: unlimited
            <br />
            <br />
            ● 有効期間または期限
            <br />
            Gコインに有効期間または期限はありません。
            <br />
            ただし、本サービスの利用規約に基づきアカウントが削除された時点で、そのアカウントのGコインは失効します。
            <br />
            <br />● ご相談窓口 <br />
            株式会社G・O・P <br />
            〒169-0075 東京都新宿区高田馬場1丁目31-18高田馬場センタービル3階{" "}
            <br />
            営業時間 : 月曜日 ~ 金曜日 (午前10時 ~ 午後7時) <br />
            非会員 :{" "}
            <Link to="https://vfun.valofe.com/customer/inquiry_not_login">
              https://vfun.valofe.com/customer/inquiry_not_login
            </Link>
            <br />
            会員 :{" "}
            <Link to="https://vfun.valofe.com/customer/inquiry">
              https://vfun.valofe.com/customer/inquiry
            </Link>
            <br />
            <br />
            <br />● 使用可能場所
            <br />
            VFUN内で提供しているゲームでご利用いただけます。 <br />
            <br />● 未使用残高の確認方法
            <br /> VFUN ページ内で以下の方法によりご確認いただけます。
            <br />
            ログイン後、ページ上の「V Wallet」をクリック
            <br />
            「メニュー」→「メンバーシップ」→「Gコイン」 <br />
            <br />● 返品・交換について
            <br />
            デジタルコンテンツという商品の特性上、お客様が購入された有償サービスおよび有償のゲーム内財貨について、いかなる理由によっても返品および交換に応じることはできません。
            <br />
            <br />● ご利用上の注意など
            <br />
            (1)いかなる状況においても、これらの金額は、何らかの理由によりお客様のアカウントが終了した場合、当社の契約が終了した場合、アイテムモールが終了した場合、および/またはサービスが終了した場合を含め、すべてまたは一部を返金はできません。
            <br />
            (2)当社は、お客様が不正な支払いをした場合に、一時的または永久的にアカウントを停止または終了する権利を留保します。詐欺またはキャンセルに関連するすべてのユーザーアカウントは、例外なく永久に停止されます。
            <br />
            <br />● 利用規約 <br />
            詳しくは利用規約をご覧ください。
            <br />
            <Link to="https://vfun.valofe.com/customer/g_pc_terms">
              https://vfun.valofe.com/customer/g_pc_terms
            </Link>
            <br />
            <br />
            <br />
            <br />
          </div>
        </Wrap>
      </div>
    </CustomerWrap>
  );
}

export default JpActTerms;

const Wrap = styled.div`
  * {
    font-size: 16px !important;
  }

  a {
    color: #8e9297;
  }
`;
