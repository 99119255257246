import React from "react";
import { faDesktop, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { langCodeState } from "atoms/contentsAtom";
import "./HottestGame.scoped.scss";
import PointRanking from "./PointRanking";
import { redirectURL } from "utils/CommonFunc";
import { t } from "i18next";
import { getHottestGame, IHottest } from "api/api";
import { POPULAR_GAME_PAGE_CODE } from "routers/contant";
import { useMediaQuery } from "react-responsive";
import { headersState, isLauncherState } from "atoms/userInfoAtom";
import FourListLoading from "components/loading/FourListLoading";

function HottestGame() {
  const langCode = useRecoilValue(langCodeState);
  const headers = useRecoilValue(headersState);
  const isLauncher = useRecoilValue(isLauncherState);
  const widthLimit = useMediaQuery({
    query: "(min-width: 641px) and (max-width: 940px)"
  });

  const limit = widthLimit ? "&limit=2" : "&limit=4";

  const { isLoading, data } = useQuery<IHottest>(
    ["hottest", langCode, limit],
    () => getHottestGame(langCode, headers, limit)
  );

  return (
    <section className="main-cont mt20">
      <div className="row-w clearfix row-flex">
        <div className="clearfix hottest">
          <div className="title-area">
            <h1>{t("hottest_game")}</h1>
            <Link to={POPULAR_GAME_PAGE_CODE} className="main-more">
              <FontAwesomeIcon icon={faPlus} className="faPlus" />
            </Link>
          </div>
          {!isLoading ? (
            <div className="tab-grid">
              {data?.result === 1 &&
                data.data.map((i) => (
                  <div key={i.idx}>
                    <div>
                      <div className="clearfix wrapper">
                        <div
                          className="img-area"
                          onClick={() =>
                            redirectURL(i.web_url, "_self", isLauncher)
                          }
                        >
                          <img src={i.thumbnail_url} alt="game-img" />
                        </div>
                        <div className="game-info-area">
                          <h3>{i.game_title}</h3>
                          <div className="genre">
                            <FontAwesomeIcon
                              icon={faDesktop}
                              className="faDesktop"
                            />
                            {i.game_style}
                          </div>
                          <div className="btn-mb-download">
                            <Link
                              to={`/library?service_code=${i.service_code}&section=home`}
                              className="a-wrap clearfix"
                            >
                              Go to play
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <FourListLoading />
          )}
        </div>
        <PointRanking />
      </div>
    </section>
  );
}

export default HottestGame;
