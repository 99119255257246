import { useRecoilValue } from "recoil";
import MobileCustomerWrap from "components/common/Wrap/MobileCustomerWrap";
import { privacyDateState } from "atoms/contentsAtom";
import parse from "html-react-parser";
import enData from "utils/data/mobilePrivacyEn.json";
import koData from "utils/data/mobilePrivacyKo.json";
import { useLocation } from "react-router-dom";

function MobilePrivacy() {
  const privacyNum = useRecoilValue(privacyDateState);
  const location = useLocation();
  const data = location.search.split("=")[1] === "ko" ? koData : enData;

  return (
    <MobileCustomerWrap title="Privacy Policy" data={data}>
      {data.map((i, idx) =>
        i.num === privacyNum ? <div key={idx}>{parse(i.text)}</div> : null
      )}
    </MobileCustomerWrap>
  );
}

export default MobilePrivacy;
