import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./CookieNotice.scoped.scss";
import { useRecoilState } from "recoil";
import { cookieNoticeHiddenState } from "atoms/uiAtom";
import { useCookies } from "react-cookie";

function CookieNotice() {
  const [isHidden, setIsHidden] = useRecoilState(cookieNoticeHiddenState);
  const [, setCookies] = useCookies(["cookie_consent"]);

  const onClickCookies = () => {
    setIsHidden(false);
    setCookies("cookie_consent", "Y", { path: "/", domain: ".valofe.com" });
  };

  const style = {
    zIndex: isHidden ? 20 : 0,
    background: "rgba(0, 0, 0, 0.3)"
  };

  return (
    <>
      <div className="shadow" style={style} onClick={onClickCookies} />
      <div id="cookieNotice" role="banner" className="cn-bottom wp-default">
        <div className="cookie-notice-container cookie">
          <span id="ckNoticeText">
            We use cookies to ensure that we give you the best experience on our
            website. If you continue to use this site we will assume that you
            consent to the cookie usage.
            <Link
              to="/customer/g_pc_privacy"
              target="_blank"
              id="cn-more-info"
              className="cn-more-info button wp-default"
            >
              Read more
            </Link>
          </span>
          <br />
        </div>
        <br />
        <Link to="#" id="cookieClose" onClick={onClickCookies}>
          <FontAwesomeIcon icon={faTimesCircle} className="close-icon" />
        </Link>
      </div>
    </>
  );
}

export default CookieNotice;
