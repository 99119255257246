import axios from "axios";
import { BASE_PATH } from "./api";
import { headersType } from "atoms/userInfoAtom";

interface IFaqData {
  category_name: string;
  idx: number;
  is_view: number;
  service_code: string;
  sort_no: number;
}

export interface IFaq {
  data: IFaqData[];
  result: number;
}

export const getFAQApi = (serviceCode: string, langCode: string) =>
  axios
    .get(
      `${BASE_PATH}/api/vfun/faqcategorylist?service_code=${serviceCode}&lang=${langCode}`
    )
    .then((res) => res.data);

interface IFaqList {
  category_name_en: string;
  ch_type: string;
  contents: string;
  idx: number;
  is_best: number;
  is_view: number;
  row_count: number;
  service_code: string;
  title: string;
}

interface IFaqListData {
  end_page: number;
  faq_list: IFaqList[];
  page: string;
}

export interface IFaqLists {
  data: IFaqListData;
  result: number;
}

export const getFAQListApi = (
  pageNum: number,
  langCode: string,
  serviceCode: string,
  faqCategory: number
) =>
  axios
    .get(
      `${BASE_PATH}/api/vfun/faqlist?page=${pageNum}&lang=${langCode}&service_code=${serviceCode}&faq_category=${faqCategory}`
    )
    .then((res) => res.data);

interface IInquiriesList {
  category_name: string;
  channel: string;
  contents: string;
  created_at: string;
  faq_category_idx: number;
  game_idx: number;
  game_title: string;
  idx: number;
  is_login: number;
  is_view: number;
  row_count: number;
  service_code: string;
  status: number;
  title: string;
  user_email: string;
  user_id: string;
  user_language: string;
}

interface IInquiriesData {
  end_page: number;
  inquiry_list: IInquiriesList[];
  page: string;
}

export interface IMyInquiries {
  data: IInquiriesData;
  result: number;
}

export const getMyInquiries = (
  pageNum: number,
  serviceCode: string,
  langCode: string,
  headers: headersType
) =>
  axios
    .get(
      `${BASE_PATH}/api/vfun/inquirylist?page=${pageNum}&service_code=${serviceCode}&lang=${langCode}`,
      { headers: headers }
    )
    .then((res) => res.data);

interface answered {
  admin_user_id: string;
  contents: string;
  created_at: string;
  idx: number;
  inquiry_idx: number;
  is_view: number;
  service_code: string;
}

interface info {
  category_name: string;
  contents: string;
  created_at: string;
  idx: number;
  next_idx: number;
  prev_idx: number;
  status: number;
  title: string;
}

export interface IInquiriesView {
  data: {
    inquiry_answered_info: answered;
    inquiry_file_list: any;
    inquiry_info: info;
  };
  result: number;
}

export const getInquiryview = (
  idx: string,
  langCode: string,
  headers: headersType
) =>
  axios
    .get(`${BASE_PATH}/api/vfun/inquiryview?idx=${idx}&lang=${langCode}`, {
      headers: headers
    })
    .then((res) => res.data);
