import ContentLoader from "react-content-loader";

function NewGameLoading() {
  return (
    <div className="row-w">
      <ContentLoader
        speed={2} // 속도
        // width={530}
        // height={180}
        viewBox="0 0 500 78"
        backgroundColor="#27292d" // 배경색
        foregroundColor="#4b4b4b" // 요소 색
      >
        <rect x="0" y="20" width="250" height="60" />
        <rect x="260" y="20" width="250" height="60" />
      </ContentLoader>
    </div>
  );
}

export default NewGameLoading;
