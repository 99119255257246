import React, { useState } from "react";
import "./HeaderSlider.scoped.scss";
import Slider from "react-slick";
import { useQuery } from "react-query";
import { getHeaderSlide, IHeaderSlide } from "api/mainApi";
import { useRecoilValue } from "recoil";
import { langCodeState } from "atoms/contentsAtom";
import SmallLoading from "components/loading/SmallLoading";
import { Link } from "react-router-dom";
import { redirectURL } from "utils/CommonFunc";
import parse from "html-react-parser";
import { isLauncherState } from "atoms/userInfoAtom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function HeaderSlider() {
  const langCode = useRecoilValue(langCodeState);
  const isLauncher = useRecoilValue(isLauncherState);
  const { isLoading, data } = useQuery<IHeaderSlide>(
    ["headerSlide", langCode],
    () => getHeaderSlide(langCode)
  );

  const [isPlaying, setIsPlaying] = useState(true);

  const settings = {
    slide: "div",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    vertical: false,
    dotsClass: "slick-dots",
    fade: true,
    pauseOnHover: true,
    appendDots: function (dots: any) {
      return (
        <div>
          <ul> {dots} </ul>
        </div>
      );
    },

    customPaging: function (i: number) {
      return !isLoading ? (
        data?.result === 1 ? (
          <div className="dot-btn">
            <span
              style={{ animationPlayState: isPlaying ? "running" : "paused" }}
            />
            <h3 className={`game-tit _${i + 1}`}>
              {data?.data[i] !== undefined ? data?.data[i].game_title : ""}
            </h3>
          </div>
        ) : (
          <div></div>
        )
      ) : (
        <div></div>
      );
    }
  };

  const [xy, setXY] = useState({ x: 0, y: 0 });

  const xyHandler = (e: any) => {
    const mouseX = e.clientX;
    const mouseY = e.clientY;

    setXY({ x: mouseX, y: mouseY >= 450 ? 450 : mouseY });
  };

  return (
    <section id="visual" className="main-header-slide">
      <div className="pointer-wrap" onMouseMove={xyHandler}>
        <div
          className="pointer"
          style={{
            transform: `translate(${xy.x}px, ${xy.y}px)`,
            display: xy.y >= 450 ? "none" : "inline-block"
          }}
        >
          Learn more
          <FontAwesomeIcon icon={faArrowRight} />
        </div>

        <Slider {...settings}>
          {!isLoading ? (
            data?.result === 1 ? (
              data.data.slice(0, 8).map((i) => (
                <div
                  key={i.idx}
                  onMouseEnter={() => setIsPlaying(false)}
                  onMouseLeave={() => setIsPlaying(true)}
                >
                  <Link
                    to="#"
                    onClick={() =>
                      redirectURL(i.link_url, i.target, isLauncher)
                    }
                  >
                    <img
                      className="slide-img"
                      src={i.image_url}
                      alt={i.title}
                    />
                    <article className="slide-article">
                      <img
                        className="logo"
                        src={i.logo_url}
                        alt={i.game_title}
                      />
                      <span className="tit">{parse(i.title)}</span>
                    </article>
                  </Link>
                </div>
              ))
            ) : null
          ) : (
            <SmallLoading customheight="584.77px" />
          )}
        </Slider>
      </div>
    </section>
  );
}

export default HeaderSlider;
