import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import GoogleOTPWrap from "components/OTPComponent/GoogleOTPWrap";
import { t } from "i18next";
import parse from "html-react-parser";
import { useRecoilValue } from "recoil";
import { emailAuthStatus } from "atoms/userInfoAtom";

function OTPLeaveCheck() {
  const emailAuth = useRecoilValue(emailAuthStatus);
  const navigate = useNavigate();

  useEffect(() => {
    if (emailAuth !== "Y") {
      navigate("/membership/sns_email_auth_confirm");
    }
  }, []);

  if (emailAuth !== "Y") {
    return null;
  }

  return (
    <GoogleOTPWrap>
      <section className="sub-cont mt0">
        <div className="row-w">
          <article className="mt20">
            <h2 className="text-center">
              <span className="bar"></span>
              <br />
              {t("g_otp_leave_auth")}
            </h2>

            <div className="box step1 text-center">
              <p className="message mt0">
                {parse(t("g_otp_leave_auth_message"))}
              </p>
            </div>
          </article>
          <div className="wrap-btn long">
            {/* 추후 개발 예정 */}
            {/* <Link to="/otp/leave_email" className="btn round-type long">
              Authorize email
            </Link> */}
            <Link to="/otp/leaveVerify" className="btn round-type long">
              {t("g_otp_gg_auth")}
            </Link>
          </div>
        </div>
      </section>
    </GoogleOTPWrap>
  );
}

export default OTPLeaveCheck;
