import axios from "axios";
import { VFUN } from "../utils/config/request";
import { headersType } from "atoms/userInfoAtom";

export const BASE_PATH = process.env.REACT_APP_VFUN_API_DNS;
export const POST_GET_MEMBERINFO = `${BASE_PATH}/api/vfun/member_info`;
export const GET_NAVER_MEMBERINFO = `${BASE_PATH}/api/naver/get_member_status/`;

export interface IMember {
  data: {
    email: string;
    nickName: string | null;
    user_Serial: string;
    user_birthday: string;
    user_id: string;
    user_profile_key: string;
    profile_image_url: string;
  };
  result: number;
  sso_info: string;
  email_auth_status: "Y" | "N";
}

export const GET_MEMBERINFO_V = (headers: {}) => {
  return VFUN({
    url: POST_GET_MEMBERINFO,
    method: "get",
    headers,
    withCredentials: true
  });
};

export const naverMemberStatus = (headers: {}, serviceCode: string) => {
  return VFUN({
    url: GET_NAVER_MEMBERINFO + `${serviceCode}`,
    method: "get",
    headers
  });
};

//// 언어 API (Languages)
interface ILangData {
  lang: string;
  lang_code: string;
}

export interface ILang {
  result: number;
  data: ILangData[];
  country: string;
}

export const getLanguage = () =>
  axios.get(`${BASE_PATH}/api/vfun/support_languages`).then((res) => res.data);

// export const getLanguage = (service_code: string) =>
//   axios
//     .get(`${BASE_PATH}/api/vfun/support_languages/${service_code}`)
//     .then((res) => res.data);

//// 배너 API (Banner)
interface IBannerData {
  idx: number;
  banner_name: string;
  banner_image_url: string;
  banner_hover_image_url: string;
  language: string;
  target: string;
  target_url: string;
  start_date: string;
  end_date: string;
}

export interface IBanner {
  result: number;
  data: IBannerData[];
}

export const getBanner = () =>
  axios
    .get(`${BASE_PATH}/api/vfun/combinemenu_banners`)
    .then((res) => res.data);

//// 사이드 메뉴 리스크 (SideMenu)
export interface ISub_Menu {
  created_at: string;
  menu_code: string;
  target: string;
  target_url: string;
  title: string;
}

export interface IMenuData {
  menu_code: string;
  sub_menu: string;
  sub_menus: ISub_Menu[];
}

export interface ICombineMenu {
  result: number;
  data: IMenuData[];
}

export const getCombineMenu = (headers: headersType, langCode: string) =>
  axios
    .get(`${BASE_PATH}/api/vfun/combinemenus?lang=${langCode}`, {
      headers: headers
    })
    .then((res) => res.data);

//// VFUN 공통 API
//// allGame
export interface IAllGameData {
  big_banner: string;
  game_genre: string;
  game_title: string;
  game_type: string;
  icon_image: string;
  idx: number;
  logo_url: string;
  service_code: string;
  small_banner: string;
  web_url: string;
  release_at: string;
  pre_creation?: string;
  vfun_lounge_flag: string;
  vfun_point_shop_flag: string;
  vfun_stamp_misson_flag: string;
}

interface IAllGame {
  data: IAllGameData[];
  result: number;
}

export const getAllGames = (langCode: string) =>
  axios
    .get(`${BASE_PATH}/api/vfun/allgamelist?lang=${langCode}`)
    .then((res) => {
      if (res.status === 200) return res.data as IAllGame;
    });

//// point shop (포인트 샵)

export interface IPointList {
  idx: number;
  game_idx: number;
  product_amount: number | null;
  product_description: string;
  product_name: string;
  product_no: string;
  product_price: number;
  service_code: string;
  thumbnail_url: string;
  game_title: string;
}

interface IPointData {
  point_shop_list: IPointList[];
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_page: number;
}

export interface IPoint {
  data: IPointData;
  result: number;
}

export const getPointShop = (
  langCode: string,
  pageNum: number,
  service_code: string
) =>
  axios
    .get(
      `${BASE_PATH}/api/vfun/pointshop/${service_code}?lang=${langCode}&page=${pageNum}&list=10`
    )
    .then((res) => res.data);

interface IShopListData {
  game_title: string;
  service_code: string;
}

export interface IShopList {
  data: IShopListData[];
  result: number;
}

export const getShopList = (langCode: string) =>
  axios
    .get(`${BASE_PATH}/api/vfun/stamp/gamelist?lang=${langCode}`)
    .then((res) => res.data);

export const purchaseApi = (service_code: string) =>
  `${BASE_PATH}/api/vfun/stamp/purchaseProduct/${service_code}`;

export const purchaseLogApi = `${BASE_PATH}/api/vfun/stamp/purchaseProductLog`;

interface IWorldInfo {
  WorldName: string;
  WorldNo: string;
}

export interface IServer {
  data?: {
    worldInfo: IWorldInfo[];
  };
  msg?: string;
  result: number;
}

export const getServerlist = (service_code: string) =>
  axios
    .get(`${BASE_PATH}/api/vfun/stamp/serverlist/${service_code}`)
    .then((res) => res.data);

export interface ICharacter {
  data: any;
  result: number;
}

export const getCharacterlist = (
  service_code: string,
  world_key: string,
  headers: headersType
) =>
  axios
    .get(
      `${BASE_PATH}/api/vfun/stamp/characterlist/${service_code}?world_key=${world_key}`,
      { headers: headers }
    )
    .then((res) => res.data);

//// hottest game (핫)
export interface IHottestData {
  game_style: string;
  game_title: string;
  game_type: string;
  idx: number;
  thumbnail_url: string;
  web_url: string;
  weekly_rank: number;
  b_weekly_rank: number;
  banner_url: string;
  service_code: string;
  like: number;
}

export interface IHottest {
  data: IHottestData[];
  result: number;
}

export const getHottestGame = (
  langCode: string,
  headers: headersType,
  limit?: string
) =>
  axios
    .get(`${BASE_PATH}/api/vfun/populargame?lang=${langCode}${limit}`, {
      headers: headers
    })
    .then((res) => res.data);

//// My Point
export const getMyPoint = (headers: headersType) =>
  axios
    .get(`${BASE_PATH}/api/vfun/mypoint`, {
      headers: headers
    })
    .then((res) => res.data);

// Myinfo
interface IMyinfoData {
  mail_type: string;
  useOTP: boolean;
  vfun_use_otp: 0 | 1;
  user_info: {
    user_id: string;
    nickname: null | string;
    user_birthday: string;
    l_name: string;
    f_name: string;
    email: string;
    mail_recv_yn: string;
  };
}

export interface IMyinfo {
  data: IMyinfoData;
  result: number;
}

export const getMyinfo = (headers: headersType) =>
  axios
    .get(`${BASE_PATH}/api/vfun/myinfo`, {
      headers: headers
    })
    .then((res) => res.data);
