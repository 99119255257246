import { isLoginModalOpenState } from "atoms/uiAtom";
import { isLoginStateSelector } from "atoms/userInfoAtom";
import customAlert from "components/common/Alert/customAlert";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { loginTranslationKey } from "utils/CommonFunc";

export default function useLoginCheck() {
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const setIsLoginModalOpen = useSetRecoilState(isLoginModalOpenState);
  const navigate = useNavigate();

  const getRetValue = () => {
    try {
      const urlObj = new URL(window.location.href); // URL 객체 생성
      return urlObj.searchParams.get("ret"); // 'ret' 파라미터 값 추출
    } catch (error) {
      console.error("유효하지 않은 URL입니다.", error);
      return null;
    }
  };

  const isNotLogin = () => {
    if (isLoginState.isLauncher) {
      window.app.vfunRequestLogin();
    } else if (isLoginState.isMobile) {
      customAlert(t(loginTranslationKey));
      return;
    } else {
      // window.dispatchEvent(new CustomEvent("loginModalOpen", { detail: true }));
      navigate(`/?ret=${getRetValue()}`);
    }
  };

  return isNotLogin;
}
