import ContentLoader from "react-content-loader";

function LibraryDetailLoading() {
  return (
    <ContentLoader
      speed={2} // 속도
      // width={530}
      // height={180}
      viewBox="0 0 500 250"
      backgroundColor="#27292d" // 배경색
      foregroundColor="#4b4b4b" // 요소 색
    >
      <rect x="0" rx="6" ry="6" y="20" width="250" height="40" />
      <rect x="0" rx="6" ry="6" y="80" width="500" height="150" />
    </ContentLoader>
  );
}

export default LibraryDetailLoading;
