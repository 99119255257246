import { useRecoilState } from "recoil";
import { popUpState } from "atoms/uiAtom";
import PopUpWrap from "components/common/PopUpWrap";
import Paging from "components/common/Paging";
import { Link } from "react-router-dom";

function LoginHistoryPopUp() {
  const [historyPopUp, setHistoryPopUp] = useRecoilState(popUpState("history"));

  const onClickClose = () => {
    setHistoryPopUp(false);
  };

  return (
    <PopUpWrap close={onClickClose} popUp={historyPopUp}>
      <h1 className="history-title">VFUN Login history</h1>
      <p className="history-desc">
        View your successful logins for the last 3 months.
      </p>
      <div className="tbl-wrapper">
        <table className="tbl-purchase">
          <tbody id="login_history">
            <tr>
              <th>Login date(UTC)</th>
              <th>Login IP</th>
              <th>Login Country</th>
            </tr>
            <tr>
              <td className="td-md">2023-06-27 06:20:54</td>
              <td className="td-md">112.216.134.18</td>
              <td className="td-md">KR</td>
            </tr>
            <tr>
              <td className="td-md">2023-06-27 03:35:19</td>
              <td className="td-md">112.216.134.18</td>
              <td className="td-md">KR</td>
            </tr>
            <tr>
              <td className="td-md">2023-06-21 08:05:51</td>
              <td className="td-md">112.216.134.18</td>
              <td className="td-md">KR</td>
            </tr>
            <tr>
              <td className="td-md">2023-06-07 11:44:55</td>
              <td className="td-md">112.216.134.18</td>
              <td className="td-md">KR</td>
            </tr>
            <tr>
              <td className="td-md">2023-06-02 06:58:37</td>
              <td className="td-md">112.216.134.18</td>
              <td className="td-md">KR</td>
            </tr>
            <tr>
              <td className="td-md">2023-06-02 05:28:32</td>
              <td className="td-md">112.216.134.18</td>
              <td className="td-md">KR</td>
            </tr>
            <tr>
              <td className="td-md">2023-06-01 05:28:13</td>
              <td className="td-md">112.216.134.18</td>
              <td className="td-md">KR</td>
            </tr>
            <tr>
              <td className="td-md">2023-05-31 03:31:55</td>
              <td className="td-md">112.216.134.18</td>
              <td className="td-md">KR</td>
            </tr>
            <tr>
              <td className="td-md">2023-05-24 12:02:05</td>
              <td className="td-md">112.216.134.18</td>
              <td className="td-md">KR</td>
            </tr>
            <tr>
              <td className="td-md">2023-05-24 02:04:21</td>
              <td className="td-md">112.216.134.18</td>
              <td className="td-md">KR</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Paging current={1} next={1} prev={0} total={1} />
      <div className="btn-wrap history-btn">
        <Link to={window.location.href}>Confirm</Link>
      </div>
    </PopUpWrap>
  );
}

export default LoginHistoryPopUp;
