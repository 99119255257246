import { Link } from "react-router-dom";
import GoogleOTPWrap from "components/OTPComponent/GoogleOTPWrap";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";

function OTPLeaveEmail() {
  const resendEmail = () => {
    console.log("이메일 다시 보내기");
  };

  return (
    <GoogleOTPWrap>
      <section className="sub-cont mt0">
        <div className="row-w">
          <article className="mt20">
            <h2 className="text-center">
              <span className="bar" />
              <br />
              {t("g_otp_leave_email_tit")}
            </h2>

            <div className="box step1 text-center">
              <p className="message mt0">
                {parse(t("g_otp_leave_email_message"))} <br />
                <br />
                <br />
                <strong className="color-blue-light">유저 이메일</strong>
                <br />
                <br />
                {parse(t("g_otp_leave_email_message2"))}
              </p>
            </div>
          </article>
          <div className="wrap-btn long">
            <Link to="/otp/leave_check" className="btn round-type long">
              <FontAwesomeIcon icon={faAngleLeft} /> {t("back")}
            </Link>
            <Link
              to={window.location.href}
              className="btn round-type long"
              onClick={resendEmail}
            >
              {t("g_otp_email_resend")}
            </Link>
          </div>
        </div>
      </section>
    </GoogleOTPWrap>
  );
}

export default OTPLeaveEmail;
