import React, { useEffect, useState } from "react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { pagingNumState } from "atoms/contentsAtom";

interface IProps {
  current?: number;
  next?: number | null;
  prev?: number | null;
  total?: number;
}

function Paging(props: IProps) {
  const [startIdx, setStartIdx] = useState(0);
  const [pagingNum, setPagingNum] = useRecoilState(pagingNumState);
  let numArr = Array.from({ length: props.total || 1 }, (_, i) => i + 1).splice(
    startIdx - 1,
    5
  );
  const total = props.total || 1;

  useEffect(() => {
    if (pagingNum <= total) {
      let newStartIdx = Math.floor((pagingNum - 1) / 5) * 5 + 1;
      setStartIdx(newStartIdx);
    }
  }, [pagingNum]);

  const onClickCurrent = (num: number) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setPagingNum(num);
  };

  // 이전
  const onClickPrev = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (pagingNum > 1) {
      setPagingNum((prevPage) => prevPage - 1);
    }
  };

  // const onDClickPrev = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  //   setPagingNum((prevPage) => (prevPage > 5 ? prevPage - 5 : 1));
  // };

  // 다음
  const onClickNext = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (pagingNum < total) {
      setPagingNum((prevPage) => prevPage + 1);
    }
  };

  // const onDClickNext = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  //   if (numArr.length === 5) {
  //     setPagingNum((prevPage) =>
  //       prevPage + 5 <= total ? prevPage + 5 : total
  //     );
  //   }
  // };

  return (
    <div className="paging">
      <ul>
        {/* {props.current === 1 || total < 5 ? null : (
          <li onClick={onDClickPrev}>
            <Link to={window.location.href} className="num" title="prev-button">
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </Link>
          </li>
        )} */}
        {props.current === 1 || total <= 5 ? null : (
          <li onClick={onClickPrev}>
            <Link to={window.location.href} className="num">
              <FontAwesomeIcon icon={faAngleLeft} />
            </Link>
          </li>
        )}
        {numArr.map((i) => (
          <li
            className={i === props.current ? "on" : ""}
            key={i}
            onClick={() => onClickCurrent(i)}
          >
            <Link to={window.location.href} className="num">
              {i}
            </Link>
          </li>
        ))}
        {props.current === props.total || total <= 5 ? null : (
          <li onClick={onClickNext}>
            <Link to={window.location.href} className="num">
              <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </li>
        )}
        {/* {props.current === props.total || total < 5 ? null : (
          <li onClick={onDClickNext}>
            <Link to={window.location.href} className="num" title="next-button">
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </Link>
          </li>
        )} */}
      </ul>
    </div>
  );
}

export default Paging;
