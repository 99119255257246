import { Link, useSearchParams } from "react-router-dom";
import MobileFaqWrap from "components/common/Wrap/MobileFaqWrap";
import FAQ from "components/SupportComponent/faq/FAQ";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { langCodeState } from "atoms/contentsAtom";
import { IFaq, getFAQApi } from "api/customer";
import { useEffect, useState } from "react";
import "components/SupportComponent/Support.scss";
import InquiryBtn from "components/SupportComponent/inquiryList/InquiryBtn";
import { useCookies } from "react-cookie";

function MobileFAQ() {
  const langCode = useRecoilValue(langCodeState);
  const [cookies, setCookie] = useCookies();
  const [searchParams] = useSearchParams();
  const service_code = searchParams.get("service_code");
  const user_app_id = searchParams.get("user_app_id");
  const user_nickname = searchParams.get("user_nickname");
  const device_info = searchParams.get("device_info");
  const game_ver = searchParams.get("game_ver");
  const os_ver = searchParams.get("os_ver");
  const platform = searchParams.get("platform");
  const game_server = searchParams.get("game_server");
  const isFaq_hero = window.location.pathname.split("/")[2];

  useEffect(() => {
    if (isFaq_hero === "faq_hero") {
      setCookie("locale", "ko", { path: "/", domain: ".valofe.com" });
    }
  }, [isFaq_hero]);

  const [faqIdx, setFaqIdx] = useState(0);
  const { isLoading: categoryListLoading, data: categoryList } = useQuery<IFaq>(
    ["faq", service_code, langCode],
    () => getFAQApi(service_code || "vfun", langCode)
  );

  useEffect(() => {
    if (!categoryListLoading && categoryList?.result === 1) {
      setFaqIdx(categoryList.data[0].idx);
    }
  }, [categoryListLoading]);

  useEffect(() => {
    if (user_app_id !== "" || user_nickname !== "") {
      setCookie("service_code", service_code, {
        path: "/",
        domain: ".valofe.com"
      });
      setCookie("user_app_id", user_app_id, {
        path: "/",
        domain: ".valofe.com"
      });
      setCookie("user_nickname", user_nickname, {
        path: "/",
        domain: ".valofe.com"
      });
      setCookie("device_info", device_info, {
        path: "/",
        domain: ".valofe.com"
      });
      setCookie("game_ver", game_ver, {
        path: "/",
        domain: ".valofe.com"
      });
      setCookie("os_ver", os_ver, {
        path: "/",
        domain: ".valofe.com"
      });
      setCookie("platform", platform, {
        path: "/",
        domain: ".valofe.com"
      });
      setCookie("game_server", game_server, {
        path: "/",
        domain: ".valofe.com"
      });
    }
  }, []);

  return (
    <MobileFaqWrap>
      {!categoryListLoading ? (
        <section className="sec1 row-w">
          <ul className="clearfix">
            {/* <li className="on">
              <Link to={window.location.href}>전체</Link>
            </li> */}
            {categoryList?.result === 1
              ? categoryList.data.map((i) => (
                  <li
                    className={i.idx === faqIdx ? "on" : ""}
                    key={i.idx}
                    onClick={() => setFaqIdx(i.idx)}
                  >
                    <Link to={window.location.href}>{i.category_name}</Link>
                  </li>
                ))
              : null}
          </ul>
        </section>
      ) : null}

      <FAQ faqIdx={faqIdx} row="row-w" />

      <InquiryBtn url="/Mobile_customer/inquiry" />
    </MobileFaqWrap>
  );
}

export default MobileFAQ;
