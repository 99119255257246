import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

function PurpleBtn(props: {
  title: string;
  onClick: () => void;
  isLoading?: boolean;
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <BtnWrap
      onClick={props.onClick}
      title={props.title}
      className={`purple-btn ${props.className}`}
    >
      {props.children}
      {/* 아이콘 추가 가능 */}
      {props.isLoading ? (
        <FontAwesomeIcon icon={faSpinner} spinPulse />
      ) : (
        props.title
      )}
    </BtnWrap>
  );
}

export default PurpleBtn;

const BtnWrap = styled.button`
  width: 100%;
  padding: 12px;
  background: #713fff;
  border-radius: 10px;
  color: var(--gray-100) !important;
  text-transform: uppercase;

  :hover {
    background: #7e58e9;
  }

  @media screen and(max-width: 440px) {
    padding: 6px;
  }
`;
