interface ISmallLoading {
  customheight: string;
  isBlack?: boolean;
}

function SmallLoading(props: ISmallLoading) {
  const { customheight } = props;
  const styles = {
    StyledWrapLoading: {
      display: "block",
      width: "100%",
      height: customheight,
      position: "relative",
      overflow: "hidden"
    } as React.CSSProperties,
    styledLoading: {
      width: "100%",
      height: "100%",
      display: "inline-block",
      position: "relative"
    } as React.CSSProperties,
    styledLoadingContainer: {
      position: "absolute"
    } as React.CSSProperties
  };
  return (
    <li style={styles.StyledWrapLoading}>
      <div className="wrap-loading" style={styles.styledLoading}>
        <div
          className="loading-container"
          style={styles.styledLoadingContainer}
        >
          <div className="spinner__container spinner__effect--8">
            <ul className="spinner" style={{ top: "-40px" }}>
              <li className="spinner__square"></li>
              <li className="spinner__square"></li>
              <li className="spinner__square"></li>
              <li className="spinner__square"></li>
              <li className="spinner__square"></li>
              <li className="spinner__square"></li>
              <li className="spinner__square"></li>
              <li className="spinner__square"></li>
              <li className="spinner__square"></li>
            </ul>
          </div>
          <span className={`loading-txt ${props.isBlack ? "black" : ""}`}>
            Loading
          </span>
        </div>
      </div>
    </li>
  );
}

export default SmallLoading;
