import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { mobileListTypeState } from "atoms/contentsAtom";
import { useRecoilState } from "recoil";

function MobileCommunityWrap(props: { children: React.ReactNode }) {
  const [searchParams] = useSearchParams();
  const service_code = searchParams.get("service_code");
  const market_idx = searchParams.get("market_idx");
  const [listType, setListType] = useRecoilState(mobileListTypeState);

  return (
    <Wrap>
      <div id="mobile-content">
        <ul className="menu-tab clearfix">
          <li
            onClick={() => {
              setListType("Notice");
            }}
          >
            <Link
              to={`/Mobile_customer/mobile_notice_list?service_code=${service_code}&market_idx=${market_idx}`}
              className={listType === "Notice" ? "on" : ""}
            >
              Notice
            </Link>
          </li>
          <li
            onClick={() => {
              setListType("Event");
            }}
          >
            <Link
              to={`/Mobile_customer/mobile_notice_list?service_code=${service_code}&market_idx=${market_idx}`}
              className={listType === "Event" ? "on" : ""}
            >
              Event
            </Link>
          </li>
        </ul>
        <section>
          <article>{props.children}</article>
        </section>
      </div>
    </Wrap>
  );
}

export default MobileCommunityWrap;

const Wrap = styled.div`
  height: 100vh;
  background: white;

  #mobile-content {
    width: 100%;
    overflow: hidden;

    .menu-tab {
      padding: 0.7rem;
      margin: 0;
      width: 100%;
      text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
      position: fixed;
      z-index: 9;
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
      background: #28303e;
      border-bottom: 1px solid #191a23;
      li {
        float: left;
        margin-left: 1rem;
        position: relative;

        a {
          color: #fff;

          &.on {
            text-decoration: underline;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        &:last-child::before {
          content: "|";
          color: rgba(255, 255, 255, 0.5);
          margin-right: 1rem;
          position: relative;
        }
      }
    }

    article {
      margin-top: 40px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 5px;
      background: -moz-linear-gradient(
        45deg,
        rgba(177, 142, 134, 1) 0%,
        rgba(88, 78, 81, 0.64) 52%,
        rgba(120, 115, 129, 0.3) 100%
      );
      background: -webkit-linear-gradient(
        45deg,
        rgba(177, 142, 134, 1) 0%,
        rgba(88, 78, 81, 0.64) 52%,
        rgba(120, 115, 129, 0.3) 100%
      );
      background: linear-gradient(
        45deg,
        rgba(177, 142, 134, 1) 0%,
        rgba(88, 78, 81, 0.64) 52%,
        rgba(120, 115, 129, 0.3) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b18e86', endColorstr='#4d787381',GradientType=1 );

      img {
        width: 100% !important;
      }
    }

    .list-group {
      padding-left: 0;
      background: #fff;
      height: calc(100% - 2.35rem);
    }

    .list-group-item {
      position: relative;
      display: block;
      padding: 10px 15px;
      margin-bottom: -1px;
      background-color: #fff;
      border: 1px solid #ddd;
      font-size: 10pt;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        color: black;
      }

      &.more-btn {
        text-align: center;

        button {
          background: transparent;
          font-weight: 400;
          color: #337ab7;
          min-width: inherit;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .list-group-item:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .bolt-label {
      display: inline-block;
      min-width: 60px;
      text-align: center;
      border-radius: 15px;
      padding: 3px 0 3px;
      font-size: 0.8em;
      border: 1px solid #36b9b6;
      color: #36b9b6;
      margin-right: 0.5em;
    }

    .board-date {
      padding: 0 0 0 2px;
      clear: inherit;
      width: auto;
      display: inline-block;
      height: 100%;
      color: #8a6d3b;
    }

    .view {
      position: relative;
      box-sizing: border-box;
      display: block;
      overflow-y: auto;
      height: calc(100% - 3.4em);
      padding: 10px 15px 20px;
      background-color: #fff;
      font-size: 13px;
      border-radius: 4px;

      .bolt-label {
        margin-top: 15px;
        margin-bottom: 20px;
        border: 1px solid #0396e4;
        color: #0396e4;
      }

      .view-title {
        box-sizing: border-box;
        display: block;
        background: transparent;
        width: 100%;
        margin-bottom: 0.25em;
        padding-left: 0;
        border-bottom: 0;
        font-size: 1.85em;
        text-shadow: none;
        color: #28303e;
      }

      .view-content {
        font-size: 0.8rem;
        line-height: 2;
        height: 100%;

        td {
          border: 1px solid #c7cbd1;
        }
      }

      .board-date {
        display: block;
        text-align: end;
        font-size: 85%;

        small:first-child {
          margin-right: 1rem;
        }
      }
    }

    .mobile_prev {
      z-index: 999;
      position: fixed;
      top: inherit;
      bottom: 1.125rem;
      left: 1rem;
      display: inline-block;
      width: 2rem;
      height: 2rem;
      text-align: center;
      background: #4f4f4f;
      border-radius: 50%;
      box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.23);

      svg {
        color: #fff;
        vertical-align: text-bottom;
      }
    }
  }
`;
