import React from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

function InquiryBtn(props: { url: string }) {
  const navigate = useNavigate();

  const onClickMemberQ = () => {
    navigate(props.url);
  };

  return (
    <section className="sec3 inquiry">
      <div className="row-w mt50">
        <h4 className="font-100">
          {t("my_inquiries_desc1")}
          <br />
          {t("my_inquiries_desc2")}
        </h4>
        <div className="wrap-btn mb60">
          <form action="">
            <legend className="hidden">Ask a question</legend>
            <label htmlFor="btn-inquiry" className="hidden" />
            <button
              type="button"
              className="btn"
              id="btn-inquiry"
              onClick={onClickMemberQ}
            >
              {t("ask_a_question")}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default InquiryBtn;
