import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { myinfoCheckEmailApi } from "api/authApi";
import { userInfoState } from "atoms/userInfoAtom";
import { useRecoilValue } from "recoil";
import { t } from "i18next";
import customAlert from "components/common/Alert/customAlert";

function MyinfoCheckEmail() {
  const [searchParams] = useSearchParams();
  const cert_code = searchParams.get("cert_code");
  const user_id = searchParams.get("user_id");
  const userInfo = useRecoilValue(userInfoState);
  const navigate = useNavigate();

  const alertMove = async () => {
    const result = await customAlert(t("email_authentication_complete_error3"));

    if (result) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (cert_code === null || user_id === null || userInfo.Userid !== user_id) {
      alertMove();
    }
    if (cert_code !== null && user_id !== null) {
      const fetchData = async () => {
        try {
          let res = await axios.post(
            myinfoCheckEmailApi,
            {
              cert_code: cert_code,
              user_id: user_id
            },
            { headers: { Userid: user_id } }
          );
          if (res.data.result === 1) {
            navigate("/membership/myinfo");
          } else if (res.data.result === -99) {
            customAlert(t("email_authentication_complete_error1"));
          } else if (res.data.result === -98) {
            customAlert(t("email_authentication_complete_error2"));
          } else if (res.data.result === -94) {
            customAlert(t("email_authentication_complete_error"));
          } else {
            customAlert(t("email_authentication_complete_error3"));
          }
        } catch (error) {
          console.log("myinfo", error);
        }
      };
      fetchData();
    }
  }, []);

  return null;
}

export default MyinfoCheckEmail;
