import { t } from "i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IAllGameData } from "api/api";
import { compareDate } from "utils/CommonFunc";

interface IGameData {
  pcData: IAllGameData[];
  mobileData: IAllGameData[];
}

function TypeTwo(props: IGameData) {
  const { pcData, mobileData } = props;

  const goLounge = (service_code: string) => {
    if (service_code === "fwtr") {
      return "https://fwtr.valofe.com/";
    } else {
      return `/library?service_code=${service_code}&section=home`;
    }
  };

  return (
    <div className="games-area type2">
      <div className="pc-game">
        <h2 className="sub-title">{t("pc_games")}</h2>
        <GameWrapper className="clearfix">
          {pcData.map((i: IAllGameData) => (
            <li key={i.idx} className={compareDate(i.release_at) ? "new" : ""}>
              <Link to={`/library?service_code=${i.service_code}&section=home`}>
                <img src={i.icon_image} alt={i.game_title} />
                {i.game_title}
              </Link>
            </li>
          ))}
        </GameWrapper>
      </div>
      <div className="mobile-game">
        <h2 className="sub-title">{t("mobile_games")}</h2>
        <GameWrapper>
          {mobileData.map((i: IAllGameData) => (
            <li key={i.idx} className={compareDate(i.release_at) ? "new" : ""}>
              <Link
                to={goLounge(i.service_code)}
                target={i.service_code === "fwtr" ? "_blank" : "_self"}
              >
                <img src={i.icon_image} alt={i.game_title} />
                {i.game_title}
              </Link>
            </li>
          ))}
        </GameWrapper>
      </div>
    </div>
  );
}

export default TypeTwo;

const GameWrapper = styled.ul`
  box-sizing: border-box;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.15);

  li {
    display: inline-block;
    margin-left: 0 !important;
    width: 24%;
    line-height: 1.75rem;
    padding: 0;
    margin-bottom: 0.5rem !important;
    background: none !important;

    :hover {
      box-shadow: none !important;
      color: rgba(60, 159, 253, 0.85) !important;
      background: none !important;
    }
  }

  a {
    display: inline-block;
    box-sizing: border-box;
    width: auto;
    padding-right: 2rem;
    color: #929292;
    font-size: 0.875rem;
    font-weight: 400;
    vertical-align: middle;

    &:hover {
      color: rgba(60, 159, 253, 0.85) !important;
      background: none !important;
    }

    img {
      margin-right: 8px;
      vertical-align: middle;
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 50%;
    }

    &:focus {
      color: #929292 !important;
      background: transparent !important;
    }
  }

  @media screen and (max-width: 1106px) {
    li {
      width: calc(33.3333% - 10px);
    }
  }

  @media screen and (max-width: 900px) {
    li {
      width: calc(50% - 10px);
    }
  }

  @media screen and (max-width: 586px) {
    li {
      width: 100%;
    }
  }
`;
