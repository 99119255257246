import React from "react";

interface IProps {
  htmlFor?: string;
  children: React.ReactNode;
  className?: string;
}

function InputWrap(props: IProps) {
  return (
    <div className={`wrapper-input ${props.className}`}>
      <label htmlFor={props.htmlFor} className="hidden"></label>
      {props.children}
    </div>
  );
}

export default InputWrap;
