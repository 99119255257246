import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { IAllGameData } from "api/api";
import { compareDate, goLibrary, outLink } from "utils/CommonFunc";

interface IGameData {
  pcData: IAllGameData[];
  mobileData: IAllGameData[];
}

function TypeOne(props: IGameData) {
  const { pcData, mobileData } = props;

  return (
    <div className="games-area">
      {/* PC */}
      <div className="pc-game">
        <h2 className="sub-title">{t("pc_games")}</h2>
        <ul className="clearfix pc-game-list">
          {pcData.map((i: IAllGameData) => (
            <li key={i.idx} className="tab">
              <div className="normal clearfix">
                <div className="img-area">
                  <img src={i.big_banner} alt={i.game_title} />
                  <span className="logo">
                    <img src={i.logo_url} alt={i.game_title} />
                  </span>
                </div>
                <div className="desc-area">
                  <div style={{ maxHeight: "84.5px" }}>
                    <h3>{i.game_title}</h3>
                    {/* 이벤트 넣을 때 ul은 null 처리하면 안됨, 각각 이벤트에 조건 달기 */}
                    <ul id="event-list">
                      <li className={compareDate(i.release_at) ? "N" : ""}>
                        new
                      </li>
                      {/* <li className="H">hot</li>
                      <li className="E">event</li> */}
                    </ul>
                    <div className="bottom">
                      <div className="genre">
                        <FontAwesomeIcon
                          icon={faDesktop}
                          style={{ paddingRight: "6px" }}
                        />
                        {i.game_genre}
                      </div>
                      <div className="pre">
                        {i.pre_creation === "Y"
                          ? t("pre_character_creation")
                          : i.pre_creation === "N"
                          ? t("pre_registration")
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hover clearfix">
                <h3>{i.game_title}</h3>
                <p>{i.game_genre}</p>
                <Link
                  to={goLibrary(i.service_code)}
                  target={outLink(i.service_code)}
                  className="a-wrap"
                >
                  Go to play
                </Link>

                {i.vfun_point_shop_flag === "Y" ? (
                  <Link
                    to={`/stamp/shop?service_code=${i.service_code}`}
                    className="a-wrap"
                  >
                    Point Shop
                  </Link>
                ) : null}

                {i.vfun_stamp_misson_flag === "Y" ? (
                  <Link
                    to={`/stamp/mission?service_code=${i.service_code}&type=stamp_daily`}
                    className="a-wrap"
                  >
                    Stamp Mission
                  </Link>
                ) : null}

                {i.vfun_lounge_flag === "Y" ? (
                  <Link
                    to={`${process.env.REACT_APP_LOUNGE_DNS}/page/${i.service_code}`}
                    className="a-wrap"
                  >
                    Go Lounge
                  </Link>
                ) : null}
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* MOBILE */}
      <div className="mobile-game">
        <h2 className="sub-title">{t("mobile_games")}</h2>
        <ul className="mobile-game-list">
          {mobileData.map((i: IAllGameData) => (
            <li key={i.idx} className="tab">
              <div className="normal clearfix">
                <div className="img-area">
                  <img src={i.small_banner} alt={i.game_title} />
                </div>
                <div className="desc mb">
                  <h3>{i.game_title}</h3>
                  <p>
                    <FontAwesomeIcon icon={faAndroid} className="icon" />
                    <FontAwesomeIcon icon={faApple} className="icon" />
                    {i.game_genre}
                  </p>
                </div>
              </div>
              <div className="hover desc clearfix">
                <h3>{i.game_title}</h3>
                <p>
                  <FontAwesomeIcon icon={faAndroid} className="icon" />
                  <FontAwesomeIcon icon={faApple} className="icon" />
                  {i.game_genre}
                </p>

                <Link
                  to={goLibrary(i.service_code)}
                  target={outLink(i.service_code)}
                  className="a-wrap"
                >
                  Details
                </Link>

                {i.vfun_point_shop_flag === "Y" ? (
                  <Link
                    to={`/stamp/shop?service_code=${i.service_code}`}
                    target="_self"
                    className="a-wrap"
                  >
                    Point Shop
                  </Link>
                ) : null}

                {i.vfun_stamp_misson_flag === "Y" ? (
                  <Link
                    to={`/stamp/mission?service_code=${i.service_code}&type=stamp_daily`}
                    target="_self"
                    className="a-wrap"
                  >
                    Stamp Mission
                  </Link>
                ) : null}

                {i.vfun_lounge_flag === "Y" ? (
                  <Link
                    to={`${process.env.REACT_APP_LOUNGE_DNS}/page/${i.service_code}`}
                    className="a-wrap"
                  >
                    Go Lounge
                  </Link>
                ) : null}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default TypeOne;
