import { refreshTokenApi } from "api/authApi";
import axios from "axios";
import { useCookies } from "react-cookie";

export const useRefreshToken = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  // 300000
  const fiveMinutesInMilliseconds = 5 * 60;
  const utc = Math.floor(Date.now() / 1000);

  const checkIfWithinFiveMinutes = async () => {
    const expiredTime =
      cookies[process.env.REACT_APP_COOKIES_EXPIRED as string];
    const timeDifference = Number(expiredTime) - utc;

    console.log(expiredTime, "vfun");

    // 목표 시간이 5분 이내인지 확인
    if (timeDifference <= fiveMinutesInMilliseconds || timeDifference <= 0) {
      try {
        await axios.post(refreshTokenApi).then((res) => {
          if (res.data.result === 1) {
            setCookie(
              process.env.REACT_APP_COOKIES_EXPIRED as string,
              res.data.data.expired_at,
              {
                path: "/",
                domain: ".valofe.com"
              }
            );
            window.location.reload();
          } else if (res.data.result === -99) {
            Object.keys(cookies).forEach((key) => {
              // 'locale' 쿠키는 항상 제외
              if (key !== "locale") {
                removeCookie(key, {
                  path: "/",
                  domain: process.env.REACT_APP_COOKIE_DOMAIN
                });
              }
            });
          }
        });

        // 결과 값으로 나온 expiredTime 갱신 필요
      } catch (error) {
        console.log(error, "refresh");
      }
    }
  };

  return checkIfWithinFiveMinutes;
};
