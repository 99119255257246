import { Link } from "react-router-dom";
import "assets/css/PopUp.scss";
import { VFUN_IMAGE_DNS } from "utils/config/Config";

function PopUpWrap(props: {
  children: React.ReactNode;
  close: () => void;
  popUp: boolean;
}) {
  const popUpStyle = {
    display: props.popUp ? "block" : "none",
    opacity: props.popUp ? 1 : 0
  };

  const shadowStyle = {
    display: props.popUp ? "block" : "none",
    opacity: props.popUp ? 1 : 0
  };

  const onClickOutside = () => {
    return props.close();
  };

  return (
    <div className="layer-wrap" id="productDesc" style={popUpStyle}>
      <div className="shadow" style={shadowStyle} onClick={onClickOutside} />
      <div className="pop-layer">
        <div className="pop-container animationOn">
          <h2 className="skip">VFUN Message</h2>
          <div className="frame">
            <Link
              to={window.location.href}
              className="btn-layer-close"
              onClick={props.close}
            >
              x
            </Link>
            <h1>
              <img
                src={`${VFUN_IMAGE_DNS}/web/vfun/images/vfun-16.png`}
                alt="VFUN Message"
              />
              VFUN Message
            </h1>
            <div className="alert" id="alertMsg">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopUpWrap;
