import { useRecoilState, useRecoilValue } from "recoil";
import PopUpWrap from "./PopUpWrap";
import { snsSharePopUpState } from "atoms/uiAtom";
import { Link } from "react-router-dom";
import { PopupShare, onCopy } from "utils/CommonFunc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isLauncherState } from "atoms/userInfoAtom";
import {
  faFacebook,
  faInstagram,
  faWeibo,
  faXTwitter
} from "@fortawesome/free-brands-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import "./SnsSharePopUp.scoped.scss";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

const data = [
  {
    type: "facebook",
    title: "Facebook"
  },
  {
    type: "twitter",
    title: "X"
  },
  // {
  //   type: "instagram",
  //   title: "Instagram"
  // },
  {
    type: "weibo",
    title: "Weibo"
  }
];

function SnsSharePopUp() {
  const [snsSharePopUp, setSnsSharePopUp] = useRecoilState(snsSharePopUpState);
  const isLauncher = useRecoilValue(isLauncherState);

  const snsType = (type: string) => {
    switch (type) {
      case "facebook":
        return faFacebook;
      case "twitter":
        return faXTwitter;
      case "weibo":
        return faWeibo;
      case "instagram":
        return faInstagram;
      default:
        return faCircle;
    }
  };

  return (
    <PopUpWrap state={snsSharePopUp} setState={setSnsSharePopUp} type="sns">
      <h4>{t("share")}</h4>
      <div>
        <ul>
          {data.map((i) => (
            <li key={i.type}>
              <Link
                to={window.location.href}
                onClick={() =>
                  PopupShare(i.type, window.location.href, "", isLauncher)
                }
              >
                <FontAwesomeIcon icon={snsType(i.type)} />
              </Link>
              <div>{i.title}</div>
            </li>
          ))}
          <li>
            <Link
              to={window.location.href}
              onClick={() => {
                onCopy(
                  window.location.href,
                  t("Link_copied"),
                  t("Link_copying_is_not_possible")
                );
                setSnsSharePopUp(false);
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </Link>
            <div>Copy</div>
          </li>
        </ul>
      </div>
    </PopUpWrap>
  );
}

export default SnsSharePopUp;
