function OTPInfo() {
  return (
    <section className="info">
      <div className="row-w">
        <h1 className="text-left clearfix">Note</h1>
        <ul className="show-info">
          <li>
            The Google verification number can be obtained from the Google
            Authenticator App upon completed registration.
          </li>
          <li>
            There is no charge for enabling the Two-Step Verification Service
            and generating verification codes.
          </li>
          <li>
            However, depending on your service provider’s company policy, data
            or internet charges may apply.
          </li>
          <li>
            After you enable the Two-Step Verification Service, you need to
            input the verification number every time, before entering the game.
          </li>
          <li className="color-mint">
            Email verification will be done if you wish to stop using the
            account you registered for the Two-Step Verification Service.
          </li>
        </ul>
      </div>
    </section>
  );
}

export default OTPInfo;
