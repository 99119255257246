import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { memberCertification } from "api/authApi";
import { t } from "i18next";
import customAlert from "components/common/Alert/customAlert";

function SignUpComplete() {
  const [searchParams] = useSearchParams();
  const cert_code = searchParams.get("cert_code");
  const user_id = searchParams.get("user_id");
  const navigate = useNavigate();

  const alertMove = async (message: string, url: string) => {
    const result = await customAlert(t(message));

    if (result) {
      navigate(url);
    }
  };

  useEffect(() => {
    if (cert_code === null || user_id === null) {
      alertMove("email_authentication_complete_error3", "/");
    }

    const fetchData = async () => {
      try {
        let res = await axios.post(memberCertification, {
          cert_code: cert_code,
          user_id: user_id
        });
        if (res.data.result === 1) {
          alertMove("email_authentication_complete", "/");
        } else if (res.data.result === -99) {
          customAlert(t("email_authentication_complete_error1"));
        } else if (res.data.result === -98) {
          customAlert(t("email_authentication_complete_error2"));
        } else if (res.data.result === -97) {
          customAlert(t("email_authentication_complete_error3"));
        } else if (res.data.result === 0) {
          customAlert(t("email_authentication_complete_error"));
        }
      } catch (error) {
        console.log("sign up auth", error);
      }
    };

    fetchData();
  }, []);

  return null;
}

export default SignUpComplete;
