import axios from "axios";
import { BASE_PATH } from "./api";
import { headersType } from "atoms/userInfoAtom";

interface IOtpData {
  game_title: string;
  thumbnail_url: string;
}

export interface IOtpList {
  msg: IOtpData[];
  result: number;
}

export const getOtpGameList = (langCode: string) =>
  axios
    .get(`${BASE_PATH}/api/vfun/otp/game_list?user_lang=${langCode}`)
    .then((res) => res.data);

// setting
export interface ISetting {
  data: {
    qr_url: string;
    secret: string;
    user_id: string;
  };
  result: number;
}

export const getSetting = (service_code: string, headers: headersType) =>
  axios
    .get(`${BASE_PATH}/api/vfun/otp/setting/${service_code}`, {
      headers: headers
    })
    .then((res) => res.data);

export const verificationApi = (service_code: string) =>
  `${BASE_PATH}/api/vfun/otp/verificationAndToggle/${service_code}`;
