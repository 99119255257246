import CustomerWrap from "components/common/Wrap/CustomerWrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

// 特定商取引法に基づく表示 - 특정상거래법 - law
// 資金決済法に基づく表示 - 자금결제법 - act

function JpLawTerms() {
  return (
    <CustomerWrap background="#33363f">
      <div id="container">
        <Wrap className="policy">
          <div id="ver1" className="contents">
            <h1>特定商取引法に基づく表示</h1>
            <br />
            <br />
            ● 販売事業者の名称および住所
            <br />
            株式会社G・O・P
            <br />
            〒169-0075 東京都新宿区高田馬場1丁目31-18高田馬場センタービル3階
            <br />
            連絡先のメールと電話番号につきましては、下記お問い合わせウェブページにご請求いただければ、ご対応いたします。
            <br />
            なお、お問い合わせへのご回答は原則ウェブページにて行っております。ご理解の程よろしくお願いいたします。
            <br />
            <br />
            ● 代表者名
            <br /> Jae-Myoung Shin
            <br />
            <br />
            ● お問い合わせ先 <br />
            株式会社G・O・P <br />
            〒169-0075 東京都新宿区高田馬場1丁目31-18高田馬場センタービル3階{" "}
            <br />
            営業時間 : 月曜日 ~ 金曜日 (午前10時 ~ 午後7時) <br />
            非会員 :{" "}
            <Link to="https://vfun.valofe.com/customer/inquiry_not_login">
              https://vfun.valofe.com/customer/inquiry_not_login
            </Link>{" "}
            <br />
            会員 :{" "}
            <Link to="https://vfun.valofe.com/customer/inquiry">
              https://vfun.valofe.com/customer/inquiry
            </Link>
            <br />
            <br />● 販売価格
            <br />
            対象となる有償財貨 (Gコイン) は販売場所に表示しています。
            <br />
            購入画面に記載されております。
            <br /> <br />● 販売価格以外の費用
            <br />
            ゲームをご利用いただく際の通信料（インターネット接続料金を含みます）は、お客様の負担となります。
            <br />
            ※料金は、お客様がご利用の携帯電話キャリアなどにお問い合わせください。
            <br />
            <br />
            ● 代金のお支払い方法・時期
            <br />
            ご利用のクレジットカード会社、プラットフォーム事業者が定めるお支払い方法・時期に基づきます。
            <br />
            <br />
            ● 支払可能金額等
            <br />
            18歳未満 : 1ヶ月5000円まで
            <br />
            18歳以上 : 無制限
            <br />
            <br />
            ● Payable amount, etc.
            <br />
            Under 18 years old: up to 5,000 yen per month
            <br />
            Over 18 years old: unlimited
            <br />
            <br />
            ● 提供時期
            <br />
            有償サービスおよび有償のゲーム内財貨は、お支払い手続き完了後、直ちに提供いたします。
            <br />
            <br />
            ● 返品・交換について
            <br />
            デジタルコンテンツという商品の特性上、お客様が購入された有償サービスおよび有償のゲーム内財貨について、いかなる理由によっても返品および交換に応じることはできません。
            <br />
            <br />
            <br />
            <br />
          </div>
        </Wrap>
      </div>
    </CustomerWrap>
  );
}

export default JpLawTerms;

const Wrap = styled.div`
  * {
    font-size: 16px !important;
  }

  a {
    color: #8e9297;
  }
`;
