import React from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { videoPopUpState } from "atoms/uiAtom";
import YouTube, { YouTubeProps } from "react-youtube";
import { videoIdState } from "atoms/contentsAtom";
import { isLauncherState } from "atoms/userInfoAtom";
import { t } from "i18next";

function VideoPopUp() {
  const [videoPopUp, setVideoPopUp] = useRecoilState(videoPopUpState);
  const videoId = useRecoilState(videoIdState);
  const isLauncher = useRecoilValue(isLauncherState);

  const popUpStyle = {
    display: videoPopUp ? "block" : "none",
    opacity: videoPopUp ? 1 : 0
  };

  const shadowStyle = {
    display: videoPopUp ? "block" : "none",
    opacity: videoPopUp ? 1 : 0
  };

  const opts: YouTubeProps["opts"] = {
    playerVars: {
      controls: isLauncher ? 0 : 1,
      disablekb: isLauncher ? 1 : 0,
      disableDoubleClick: 1
    }
  };

  return (
    <div className="layer-wrap" id="yt" style={popUpStyle}>
      <div
        className="shadow"
        style={shadowStyle}
        onClick={() => setVideoPopUp(false)}
      />
      <div className="pop-layer">
        <div className="pop-container">
          <Link
            to={window.location.href}
            className="close"
            id="closeyt"
            onClick={() => setVideoPopUp(false)}
          >
            {t("close")}
          </Link>
          <h2 className="skip">VideoPopUp</h2>
          <div className="frame">
            <YouTube videoId={videoId[0]} opts={opts} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPopUp;
