import {
  faChevronLeft,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { privacyDateState } from "atoms/contentsAtom";

interface Idate {
  date: string;
  num: number;
}

function MobileCustomerWrap(props: {
  children: React.ReactNode;
  title: string;
  data: Idate[];
}) {
  const navigate = useNavigate();
  const setPrivacyDate = useSetRecoilState(privacyDateState);

  const onSelectDate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPrivacyDate(Number(e.target.value));
  };
  return (
    <Wrap>
      <header className="mobile_onepage_header">
        <Link to="#" className="mobile_prev" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Link>
        <Link
          to={`${process.env.REACT_APP_PLATFORM_DNS}`}
          className="mobile_logo"
        ></Link>
        <Link to="#" className="mobile_close">
          <FontAwesomeIcon icon={faTimesCircle} />
        </Link>
      </header>
      <div className="container">
        <div>
          <div className="wrap-select">
            <select className="sel-version" onChange={(e) => onSelectDate(e)}>
              {props.data.map((i) => (
                <option value={i.num} key={i.num}>
                  {i.date}
                </option>
              ))}
            </select>
          </div>
          <div className="cont">
            <h1>{props.title}</h1>
            {props.children}
          </div>
        </div>
      </div>
    </Wrap>
  );
}

export default MobileCustomerWrap;

const Wrap = styled.div`
  background: #fff;

  .mobile_onepage_header {
    position: relative;
    z-index: 8888;
    clear: both;
    font-size: 1rem;
    width: 100%;
    height: 60px;
    text-align: left;
    background-color: #131313 !important;

    svg {
      vertical-align: middle;
      color: #fff;
      font-size: 24px;
    }

    a {
      display: inline-block;
      /* height: 100%; */
      position: absolute;
    }

    .mobile_prev {
      left: 1rem;
      top: 1rem;
    }

    .mobile_close {
      right: 1rem;
      top: 1rem;
    }
  }

  .mobile_logo {
    display: inline-block;
    position: absolute;
    top: 19.5px;
    left: 50%;
    margin-left: -40.5px;
    vertical-align: middle;
    width: 81px;
    height: 23px !important;
    line-height: 30px;
    color: #fff;
    text-align: center;
    background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/logo/logo-vfun.png)
      0 center no-repeat;
    background-size: contain;
  }

  .container {
    width: 96%;
    margin: 0 auto;
    padding-bottom: 30px;
  }

  .wrap-select {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: right;
    border-bottom: 1px solid #dedede;
  }

  .sel-version {
    box-sizing: border-box;
    display: inline-block;
    width: 140px;
    height: 32px;
    margin: 0px 0px 0px 8px;
    padding: 6px 7px 8px;
    font-size: 12px;
    text-shadow: none;
    line-height: 1.2;
    vertical-align: middle;
  }

  .cont {
    font-size: 13px;
    line-height: 1.7;

    h1 {
      padding: 15px 0 15px 0;
      font-size: 20px;
      letter-spacing: -1px;
      font-weight: bold;
      color: #f39a00;
      border-bottom: 5px solid #f39a00;
      margin-bottom: 15px;
      margin-top: 15px;
    }

    h2 {
      margin: 22px 0 15px;
      font-size: 16px;
      letter-spacing: -1px;
      font-weight: bold;
      color: #444;
    }

    h3 {
      margin: 22px 0 15px;
      font-size: 14px;
      letter-spacing: -1px;
      font-weight: bold;
      color: #444;
    }

    h4 {
      font-size: 14px;
      color: #444;
    }
  }

  blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  .policy_tbl {
    table-layout: fixed;
    word-break: break-word;
    width: 100%;
    text-indent: 0;
    margin-bottom: 8px;
    margin-bottom: 0.5rem;

    p {
      padding: 1rem 0;
    }

    tr {
      td {
        text-align: center;
        padding: 6px 0 6px 0px;
        border: 1px solid #cdcdcd;
        word-break: break-word;
        text-indent: 0;
        font-size: 13px;
      }
      th {
        border: 1px solid #cdcdcd;
        border-top: 5px solid #f49e0b;
        border-bottom: 1px solid #f49e0b;
        text-align: center;
        padding: 8px 0 8px 5px;
        background: #f5f5f5;
        word-break: break-word;
        text-indent: 0;
      }
    }

    tr:nth-child(1) {
      th {
        border-bottom: 1px solid #f49e0b;
      }

      td {
        border-top: 1px solid #f49e0b;
      }
    }

    tr:last-child td {
      border-bottom: 1px solid #f49e0b;
    }
  }
`;
