import { t } from "i18next";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { Dialog } from "./DialogStyle";

interface CustomAlertProps {
  message: string;
  onConfirm: () => void;
}

const CustomAlert = ({ message, onConfirm }: CustomAlertProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleConfirm = () => {
    setShowModal(false);
    setTimeout(() => onConfirm(), 1);
  };

  useEffect(() => {
    setTimeout(() => setShowModal(true), 1);
  }, []);
  return (
    <Dialog.Container showModal={showModal}>
      <div className="shadow" />
      <Dialog.Content>
        <Dialog.Message>{message}</Dialog.Message>
        <Dialog.Buttons>
          <Dialog.Button onClick={handleConfirm} color="p">
            {t("confirm")}
          </Dialog.Button>
        </Dialog.Buttons>
      </Dialog.Content>
    </Dialog.Container>
  );
};

const customAlert = (message: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const handleConfirm = () => {
      const modalRoot = document.getElementById("modal-alert-portal-wrapper");
      if (modalRoot) modalRoot.remove();

      resolve(true);
    };
    if (typeof window !== "undefined") {
      const subDiv = document.createElement("div");
      subDiv.id = "modal-alert-portal-wrapper";
      document.body.appendChild(subDiv);

      const root = createRoot(subDiv);
      root.render(<CustomAlert message={message} onConfirm={handleConfirm} />);
    }
  });
};

export default customAlert;
