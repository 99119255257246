import { t } from "i18next";
import CombineMenu from "components/common/CombineMenu/CombineMenu";
import { VFUN_IMAGE_DNS } from "utils/config/Config";
import { Link } from "react-router-dom";
import Footer from "components/common/Footer/Footer";
import { isLoginStateSelector } from "atoms/userInfoAtom";
import { useRecoilValue } from "recoil";

function Download() {
  const isLoginState = useRecoilValue(isLoginStateSelector);

  const onClickDownload = () => {
    const fileUrl = process.env.REACT_APP_LAUNCHER_DOWN as string;
    const fileName = "VFUNLauncherInstaller.exe";

    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    link.click();
  };

  return (
    <div className="wrapper">
      {/* {!isLoginState.isMobile && <CombineMenu />} */}
      <div id="NotSupport" className="clearfix">
        <div className="guide-top">
          <div className="guide-funny">
            <img
              src={VFUN_IMAGE_DNS + "/web/vfun/images/funny-download-gude.png"}
              alt="funny-browser-gude"
            />
          </div>
          <div className="text">
            <div>{t("VFUN Launcher Download")}</div>
            <div className="small-txt">{t("meet_message")}</div>
          </div>
          <div className="btns">
            <Link className="btn-window" to="#" onClick={onClickDownload}>
              <div className="cont">
                <img
                  src={VFUN_IMAGE_DNS + "/web/vfun/images/icon-valofe.png"}
                  alt="icon-windows"
                />
                <span className="btn-text download">
                  <div>VFUN Launcher</div>
                  <div>{t("download")}</div>
                </span>
                <img
                  src={
                    VFUN_IMAGE_DNS +
                    "/web/vfun/images/vfun_lounge/images/icon-angle-right.png"
                  }
                  alt="icon-angle-right"
                />
              </div>
            </Link>
          </div>
        </div>
        <div className="guide-bottom">
          <div className="install-box">
            <div className="title">VFUN Launcher</div>
            <div className="box">
              <Link className="go-btn" to="#" onClick={onClickDownload}>
                <img
                  className="icon-launcher-small"
                  src={VFUN_IMAGE_DNS + "/web/vfun/images/icon-VLauncher.png"}
                  alt="VLauncher_icon_256"
                />
                <span>Launcher {t("download")}</span>
                <img
                  src={
                    VFUN_IMAGE_DNS +
                    "/web/vfun/images/vfun_lounge/images/icon-down.png"
                  }
                  alt="icon-down"
                />
              </Link>
              <p>
                {t("meet_message")}
                <br />
                {t(
                  "all_games_can_be_installed_and_played_in_a_pleasant_environment"
                )}
              </p>
            </div>
          </div>
          <div className="install-box">
            <div className="title">
              {t("system_recommended_specifications")}
            </div>
            <div className="box-launcher">
              <div>
                <img
                  src={VFUN_IMAGE_DNS + "/web/vfun/images/icon-VLauncher.png"}
                  alt="VLauncher_icon_256"
                />
                <div>VFUN 2.5</div>
              </div>
              <div className="launcher-info">
                <table>
                  <colgroup>
                    <col width="40%" />
                    <col width=" 60%" />
                  </colgroup>
                  {/* <thead>
                    <tr>
                      <th>시스템 권장 사양</th>
                      <th>권장 사양</th>
                    </tr>
                  </thead> */}
                  <tbody>
                    <tr>
                      <td>{t("launcher_size")}</td>
                      <td>{t("launcher_size_desc")}</td>
                    </tr>
                    <tr>
                      <td>{t("function")}</td>
                      <td>{t("install_and_run_the_game")}</td>
                    </tr>
                    <tr>
                      <td>{t("delete_method")}</td>
                      <td>
                        {t(
                          "control_panel_add_remove_programs_select_vfunlauncher"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("impact_on_other_programs")}</td>
                      <td>{t("doesnt_exist")}</td>
                    </tr>
                    <tr>
                      <td>{t("operating_system")}</td>
                      <td>
                        Windows 7(Service Pack 1), Windows 8.1, Windows 10
                      </td>
                    </tr>
                    <tr>
                      <td>{t("launcher_graphics")}</td>
                      <td>{t("launcher_graphics_card")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Download;
