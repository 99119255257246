import styled, { css } from "styled-components";

export const Dialog = {
  Container: styled.div<{ showModal: boolean }>`
    position: fixed;
    top: -100px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.1s ease-in-out;
    cursor: default;
    z-index: 999;

    ${({ showModal }) =>
      showModal &&
      css`
        top: 0;
        opacity: 1;
        pointer-events: auto;
      `}
  `,

  Content: styled.div`
    margin-top: 60px;
    background: var(--gray-800);
    border-radius: 8px;
    padding: 30px 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    max-width: 360px;
    width: 100%;
    position: relative;
  `,

  Message: styled.div`
    font-size: 14px;
    color: #fff;
  `,

  Buttons: styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `,

  Button: styled.button<{ color: string }>`
    margin-top: 20px;
    border-radius: 4px;
    background: ${(props) => (props.color === "p" ? "#713FFF" : "#323232")};
    font-size: 12px;
    width: 150px;
    padding: 10px;
    color: #fff;

    &:hover {
      background: ${(props) => (props.color === "p" ? "#7e58e9" : "#3f3e3e")};
      color: #fff;
    }

    &:last-child {
      margin-left: 20px;
    }
  `
};
