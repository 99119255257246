import MobileCustomerWrap from "components/common/Wrap/MobileCustomerWrap";

const data = [{ date: "2019.02.07", num: 0 }];

function MobileTerms() {
  return (
    <MobileCustomerWrap title="Terms Of Agreement" data={data}>
      <br />
      YOU MUST READ, UNDERSTAND AND ACCEPT THE PROVISIONS OF THESE TERMS OF
      SERVICE BEFORE USING OUR SERVICES. BY CLICKING “I ACCEPT”, YOU AGREE TO BE
      BOUND BY THESE TERMS. <br /> <br />
      You also hereby warrant and represent that you either: <br />
      1) have the legal capacity to agree to these Terms and are legally
      competent to read, understand and accept the provisions of these Terms on
      behalf of yourself; or <br />
      2) represent that your legal guardian has reviewed and agreed to these
      Terms and given consent in accordance with the applicable laws and in
      accordance with any relevant VALOFE policy.
      <br />
      <br />
      These Terms of Service (“Terms”) apply between VALOFE Corporation
      (“VALOFE” or “we”, “our” or “us”), and You (as defined below), if you
      reside outside of the Republic of Korea, regarding your use of VALOFE’s
      game application (“Game”) and related services, including services
      provided via the valofe.com website (“Services”); if you reside outside of
      the Republic of Korea, these Terms will prevail over any other agreements
      or terms of service you may have entered into or will have entered into
      with us with respect to your use of the Game; depending on your place of
      residence, Special Terms of Service regarding to your current residence;
      which are different by each country shall apply.
      <br />
      <br />
      These Terms consist of the terms and conditions below together with our
      other policies or rules of conduct and our Privacy Policy (as may be
      amended from time to time)(together, “VALOFE Policies”) which is
      incorporated herein by reference. VALOFE will post any changes and
      modifications to these Terms and Privacy Policy on its website. Please
      ensure that you keep up to date with the most current versions by visiting
      valofe.com on a regular basis.
      <br />
      <br />
      <h2>1. LIMITED LICENSE</h2>
      <br />
      1.1 License Grant – Subject to your acceptance and compliance with the
      conditions herein and all other policies or rules of conduct, VALOFE
      hereby grants You a revocable, non-exclusive, license for personal and
      non-commercial use of the Game and Services that is non-transferable. Any
      Services or software (“Software”) supplied by VALOFE in relation to the
      Game is licensed, not sold, by VALOFE. All title and rights not expressly
      granted in these Terms of Service, including but not limited to any
      Intellectual Property (“IP”) right and the display thereof, are retained
      by VALOFE and/or third-parties under agreement with VALOFE. IP rights
      includes any or all of the trademarks, trade names, patent, copyright,
      registered designs, trade secrets or other industrial or intellectual
      property right subsisting in any territory. <br />
      <br />
      1.2 License Pre-Conditions – You acknowledge that any VALOFE license to
      use the Services, the content and the Game (and/or the creation of any
      Character) is pre-conditioned upon Your agreement to comply with all
      provisions of these Terms. YOU ACKNOWLEDGE THAT ANY SUCH LICENSE
      TERMINATES IMMEDIATELY ON NOTICE TO YOU IF YOU BREACH, OR OTHERWISE FAIL
      TO COMPLY WITH ANY PROVISION OF THESE TERMS, OR IF YOU ARE FOUND TO HAVE
      PREVIOUSLY BREACHED OR FAILED TO COMPLY WITH VALOFE’S TERMS OF SERVICE OR
      ITS POLICIES. <br />
      <br />
      1.3 Minors - You shall restrict use by minors. You accept full
      responsibility for any use or action in respect of the Services by minors
      and You hereby consent to these Terms on behalf of such minors. <br />
      <br />
      1.4 License Restrictions – You agree that You will not, directly or
      indirectly, (except as expressly permitted under applicable law): <br />
      <br />
      (i) reverse engineer, translate, adapt, disassemble, decompile, or reduce
      to any human-perceivable form, any Software or parts thereof, or other
      intellectual property used to provide the Services or the Game; <br />
      <br />
      (ii) copy, distribute or reproduce any Services, any content therein,
      Software, or parts thereof; <br />
      <br />
      (iii) modify, or create a derivative work of, any Services, any content
      therein, Software, or parts thereof; and/or <br />
      <br />
      (iv) sell, sub-license, rent, lease, grant a security interest in, borrow,
      lend, loan, network or engage in any activity that could in any way
      transfer or provide others access to any Services, any content therein,
      Software, or parts thereof, including but not limited to any serial code
      number, access key or the like.
      <br />
      <br />
      <br />
      <h2>2. ACCOUNT(S), SERVICES AND PAYMENTS</h2>
      <br />
      2.1 Account(s) – The Game may require you to create an VALOFE account
      (“Account”). It is Your responsibility to establish any Account needed or
      recommended for the Game and to comply with any VALOFE Policies for
      establishing and maintaining such Account(s). You are responsible for any
      use and related liabilities with respect to any Account for which You
      click “I ACCEPT,” whether or not such use and related liabilities were in
      any way authorized by You. It is Your responsibility to keep your Account
      information up to date, and safeguard any access control mechanisms You
      may have related to an Account such as a password or PIN.
      <br /> <br />
      FOR MOBILE APPLICATIONS, PLEASE KEEP IN MIND THAT YOU MAY NOT RECEIVE
      CUSTOMER SUPPORTS OR MAY NOT USE VIRTUAL ITEMS FOR WHICH YOU HAVE PAID IF
      YOU CHANGE YOUR DEVICE OR DELETE THE GAME FROM YOUR DEVICE WITHOUT
      CREATING AN ACCOUNT.
      <br /> <br />
      2.2 Payments Related to Services and Content –You may be required to pay
      fees in relation to any Services or any content therein. YOU ACKNOWLEDGE
      AND AGREE THAT (a) UNLESS REQUIRED BY RELEVANT LAWS, VALOFE HAS NO
      OBLIGATION TO REFUND ANY PAYMENTS IN WHOLE OR IN PART, AND (b) THAT VALOFE
      MAY TERMINATE ANY ACCOUNT RELATED TO ANY ATTEMPT TO OBTAIN A REFUND OF ANY
      PAYMENTS.
      <br /> <br />
      2.3 No Cash Value of Virtual Items – Within the Services, You may be
      allowed to obtain “virtual currency” or “in-game items” from VALOFE (or a
      third party authorized by VALOFE) for use in connection with the Game
      (together, “Virtual Items”) or other offerings by VALOFE. You acknowledge
      that a Virtual Item is digital material with no cash value, that no
      interest is paid or earned with respect to any Virtual Item, that Virtual
      Item is not personal property, that the quantity of Virtual Item in Your
      Account may be increased, decreased, modified or removed by VALOFE in its
      sole and absolute discretion for any reason or no reason whatsoever, that
      You have no right to a refund related to a Virtual Item, that there is no
      right to transfer or exchange any Virtual Item. VALOFE may limit Your
      license to use any Virtual Item or Services, content or activity, as well
      as any time period related thereto. In addition, You also acknowledge and
      further agree that You have no right to obtain any recompense (or
      compensation) for the foregoing. <br /> <br /> <br />
      <h2>3. YOUR RESPONSIBILITIES AND CONDUCT</h2>
      <br />
      You agree that You may not and will not,
      <br />
      <br />
      (a) engage in any activity, including without limitation, with respect to
      any Account or Character, such as: (i) providing VALOFE any inaccurate
      information regarding an Account; (ii) selecting an Account or Character
      ID that would require any license from any third party; (iii) implying any
      affiliation with VALOFE or impersonating any other person;
      <br />
      <br />
      (b) access or use the Account or personal information of another person
      without authorization;
      <br />
      <br />
      (c) select an Account, Character ID or provide any communication or
      information within the Services that VALOFE, in its sole and absolute
      discretion, deems to be vulgar, threatening, defamatory, libelous,
      obscene, racist, sexist, promoting sexual violence, advocating
      homosexuality or lesbianism, promoting incest, paedophilia, bestiality and
      necrophilia, glorifying or inciting ethnic, racial or religious hatred, or
      otherwise offensive.
      <br />
      <br />
      (d) misappropriate, violate or infringe VALOFE or any third-party IP
      right;
      <br />
      <br />
      (e) be a party to any commercial activity related to the Game, including
      but not limited to: (i) providing or obtaining any digital material or
      content, including but not limited to any Virtual Item, any Account, or
      Character.
      <br />
      <br />
      (f) use, or provide others with, any “hack,” “cheat,” “exploit” or “mod”
      or any other unauthorized software including any automation software
      (a.k.a. “bot”) or software designed to change or modify operation of the
      Game; or provide any service that interacts with the Software.
      <br />
      <br />
      (g) knowingly affect the Services, the Game, any content or Software via
      any bloatware, malware, computer virus, worm, Trojan horse, spyware,
      adware, crimeware, scareware, rootkit or any other program installed in a
      way that executable code of any program is scheduled to utilize or
      utilizes processor cycles during periods of time when such program is not
      directly or indirectly being used;
      <br />
      <br />
      (h) use any VALOFE IP right except as permitted under these Terms or
      otherwise permitted in writing by VALOFE;
      <br />
      <br />
      (i) engage in any activity prohibited under Section 1.4 above;
      <br />
      <br />
      (j) violate or infringe copyright laws, or in any way circumvent (or
      attempt to circumvent) any VALOFE technological measure intended to
      effectively control access to the Services, the Game, its content,
      Software, or any other VALOFE IP right, circumvent any protection afforded
      by an VALOFE technological measure to any VALOFE IP right, or engage in
      any trafficking related to the foregoing;
      <br />
      <br />
      (k) post or send any information (including software programs and files)
      prohibited by VALOFE;
      <br />
      <br />
      (l) modify or change any part of the Services or any information
      (including software programs and files) posted by VALOFE;
      <br />
      <br />
      (m) violate any law or governmental regulation related to the Game;
      <br />
      <br />
      (n) gain unfair profit by deceit or fraud with respect to other users; and
      <br />
      <br />
      (o) solicit, advertise or assist others in any of the foregoing
      activities.
      <br />
      <br />
      <br />
      <h2>4. TERMINATION OF ACCOUNT AND SERVICE</h2>
      <br />
      4.1 Account Suspension and/or Termination for Cause – VALOFE may limit,
      suspend, modify, prohibit access to, and/or delete for cause (e.g., You
      breach, violate, or otherwise fail to comply with any of these Terms or
      VALOFE Policies, or if such noncompliance, or illegal or improper activity
      is suspected) Your Account, all related content and/or characteristics
      related to a Character, with or without any notice. Any suspension and/or
      termination hereunder, as well as the timing thereof, shall be
      conclusively determined in the sole and absolute discretion of VALOFE. YOU
      HEREBY WAIVE ANY RIGHTS YOU MAY HAVE HAD TO NOTICE OF TERMINATION FROM
      VALOFE. YOU CAN LOSE YOUR ACCOUNT AND CHARACTER AS A RESULT OF ACCOUNT
      TERMINATION OR LIMITATION, AS WELL AS ANY BENEFITS, PRIVILEGES, VIRTUAL
      ITEMS ASSOCIATED WITH YOUR USE OF THE SERVICES. VALOFE IS UNDER NO
      OBLIGATION TO COMPENSATE YOU FOR ANY SUCH LOSSES OR RESULTS.
      <br />
      <br />
      4.2 Effect – Suspension and/or termination of an Account under this
      Section also suspends and/or terminates (as applicable) any license
      granted by VALOFE under these Terms. Termination of any license granted by
      VALOFE is without prejudice to VALOFE’s other rights or remedies under
      these Terms, and is without prejudice to the coming into force or the
      continuance in force of any provision hereof which is expressly or by
      implication intended to come into or continue in force on or after such
      termination.
      <br />
      <br />
      4.3 VALOFE reserves the right to stop offering and/or supporting the Game,
      Services or part of the Services at any time by announcement on our
      website, at which point your license to use the Services or a part thereof
      will be automatically terminated. In such event, unless required by
      relevant laws, VALOFE shall not be required to provide refunds, benefits
      or other compensation to users in connection with such discontinued
      Services or Game.
      <br />
      <br />
      <br />
      <h2>5. USER CONTENT</h2>
      <br />
      5.1 Your Representations and Warranties – By transmitting, submitting, or
      posting any communications, images, sounds, and all the material, data,
      and information that you or other users upload, submit, or transmit
      through the Services (“User Content”) while using the Services, you
      affirm, represent and warrant that such transmission or submission is (a)
      accurate and not confidential; (b) not in violation of any applicable
      laws, contractual restrictions or other third party rights, and that you
      have permission from any third party whose personal information or
      intellectual property is comprised in the User Content; and (c) free of
      viruses, adware, spyware, worms or other malicious code. You acknowledge
      and agree that you are solely responsible for all User Content you post or
      otherwise transmit via the Services.
      <br />
      <br />
      5.2 User Conduct – VALOFE has no obligation under any circumstances to
      monitor user conduct or other activities related to the Game, nor, to
      review any information, feedback or communication related to the Game or
      user interactions, and assumes no responsibility for the failure to
      monitor any user conduct, including User Contents. VALOFE may, in its sole
      and absolute discretion, choose to monitor user conduct or other
      activities related to the Game, or review any information, feedback or
      communication related to the Game. You consent to such monitoring,
      including but not limited to monitoring in-Game communications, Service or
      Software provided by VALOFE, at any time and in any manner, including but
      not limited to monitoring communications interfaces, storage devices,
      random access memory or CPU processes related to hardware you use with the
      Game.
      <br />
      <br />
      You acknowledge that VALOFE may take any action, or no action whatsoever,
      based on such monitoring, information, feedback or communication,
      including but not limited to: (i) changing any portion of the Services,
      the content or Software; or (ii) terminating your Account. VALOFE assumes
      no responsibility for, and You agree not to seek to hold VALOFE liable
      for, the conduct of or the contents submitted by any third parties,
      including other users.
      <br />
      <br />
      You acknowledge that You have no obligation to provide VALOFE any
      information, feedback or communications related to the Game. You further
      agree to defend, indemnify and hold harmless VALOFE with respect to any
      claim, cost, demand, expense or liability related to any information,
      feedback or communications You provide.
      <br />
      <br />
      <br />
      <h2>6. PUSH NOTIFICATIONS, SMS NOTIFICATIONS</h2>
      <br />
      VALOFE may send local or push notifications to your device to make you
      aware of game updates, promotional events, new game information and other
      relevant messages regarding the Services. You can manage push
      notifications from the “options” or “settings” page within the Game or
      Services. You may also be able to manage them from your device’s settings
      page. VALOFE will obtain consents required in compliance with any
      applicable laws or regulations, if necessary, before sending any SMS or
      push notification to your device.
      <br />
      <br />
      <br />
      <h2>7. THIRD PARTY ADVERTISING</h2>
      <br />
      The Game or Service may feature advertisements from third parties or
      provide links on the Services to third party websites or vendors who may
      invite you to participate in a promotional offer in return for receiving
      an optional component of the VALOFE Service and/or benefits (such as
      Virtual Items). Any charges, payments or “donations” or obligations you
      incur in your dealings with these third parties are your sole
      responsibility, and you acknowledge that VALOFE has no responsibility with
      respect to the foregoing. VALOFE makes no representation or warranty
      regarding any content, goods and/or services provided by any third party,
      and will not be liable for any claim, cost, demand, expense or liability
      relating to any third party content, goods and/or services, business
      practices or privacy policies, or for the collection, use or disclosure of
      any information those sites may collect. The linked sites are not under
      the control of VALOFE and may collect data or solicit personal information
      from you.
      <br />
      <br />
      <br />
      <h2>8. INTELLECTUAL PROPERTY</h2>
      <br />
      8.1 You acknowledge and agree that You have no IP right related to the
      Game or any Services, the content therein, Software, or any combination of
      the foregoing or parts thereof except the limited license provided herein.
      <br />
      <br />
      8.2 You acknowledge, and further agree, that You have no ownership or IP
      right related to any Account, any communication or information provided by
      You or anyone else, any information, feedback or communication related to
      the Game, any Character or characteristics related to a Character, any
      combination of the foregoing or parts thereof, or any combination of the
      foregoing with any Services, content, Software, or parts thereof. All
      rights in and to an Account are and shall be owned by and inure to the
      benefit of VALOFE.
      <br />
      <br />
      8.3 To the extent You may claim any such IP right(s), including but not
      limited with respect to User Content that you upload, submit or transmit
      through the Service, You hereby grant VALOFE a worldwide, non-exclusive,
      no-charge, royalty-free, sub-licensable, perpetual and irrevocable license
      and full authorization to exercise all rights of any kind or nature
      associated with such IP right(s), and all ancillary and subsidiary rights
      thereto, in any languages and media now known or not currently known. Your
      license to VALOFE includes, but is not limited to, all necessary trademark
      licenses, all copyright licenses needed to reproduce, display, publicly
      perform, distribute and prepare derivative works of any such IP right, and
      all patent licenses needed to make, have made or otherwise transfer, use,
      offer to sell, sell, export and import related to such IP right(s). To the
      extent permitted by applicable laws, you hereby waive any moral rights you
      may have in any User Content. In addition to the provisions of Section 11
      below, You further agree to defend, indemnify and hold harmless VALOFE
      with respect to any claim, cost, demand, expense and/or liability by or
      involving third parties that any such license to any such IP right(s)
      misappropriates, violates or infringes any third party IP right or other
      proprietary rights.
      <br />
      <br />
      <br />
      <h2>9. DISCLAIMER OF WARRANTIES</h2>
      <br />
      EACH GAME AND THE SERVICES IS/ARE PROVIDED ON AN “AS IS” AND “AS
      AVAILABLE” BASIS. WE MAKE NO WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
      INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF TITLE, NON-INFRINGEMENT,
      QUIET ENJOYMENT, ACCURACY, COMPLETENESS, OPERABILITY, QUALITY OF SERVICE,
      MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE OR USE. WE DO NOT
      WARRANT THAT THE SERVICES WILL BE ERROR FREE OR UNINTERRUPTED, THAT THEY
      ARE FREE FROM DEFECTS OR VIRUSES OR THAT DEFECTS WILL BE CORRECTED. WE
      DISCLAIM ALL RESPONSIBILITY AND LIABILITY ARISING FROM OR RELATING TO THE
      AVAILABILITY, OPERABILITY, TIMELINESS, SECURITY, RELIABILITY, QUALITY OF
      THE GAME AND SERVICE, ANY RELATED SOFTWARE OR APPLICATION, OR OTHER
      PRODUCTS, SERVICES, INFORMATION OBTAINED THROUGH THE GAME. WE HAVE NO
      RESPONSIBILITY OR LIABILITY FOR: THE LOSS OR DELETION OF, OR THE FAILURE
      TO STORE OR TO TRANSMIT, ANY CONTENT AND OTHER INFORMATION MAINTAINED OR
      TRANSMITTED THROUGH THE SERVICES. WE ARE NOT RESPONSIBLE FOR THE ACCURACY
      OR RELIABILITY OF ANY INFORMATION OR ADVICE TRANSMITTED THROUGH THE
      SERVICES.
      <br />
      <br />
      <br />
      <h2>10. LIMITATION OF LIABILITY</h2>
      <br />
      IN NO EVENT SHALL VALOFE, ANY THIRD PARTIES UNDER AGREEMENT WITH VALOFE,
      ANY SUBSIDIARY OR AFFILIATE OF VALOFE BE LIABLE FOR ANY INDIRECT,
      INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY, LIQUIDATED, OR CONSEQUENTIAL
      DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR
      SERVICES; LOSS OF USE, REVENUE, DATA, OR PROFITS; OR BUSINESS
      INTERRUPTION) HOWEVER CAUSED OR BASED ON ANY THEORY OF LIABILITY OR CAUSE
      OF ACTION ARISING IN ANY WAY RELATED TO THESE TERMS OR USE OF THE GAME,
      EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL PURPOSE AND EVEN IF ADVISED OF
      THE POSSIBILITY OF SUCH DAMAGE.
      <br />
      <br />
      BY ENTERING INTO THIS AGREEMENT TO USE VALOFE SERVICES, CONTENT OR
      SOFTWARE YOU ACCEPT THE FOREGOING LIMITATION OF LIABILITY / ALLOCATION OF
      RISK AND AGREE TO RELEASE VALOFE, ANY THIRD PARTIES UNDER AGREEMENT WITH
      VALOFE, ANY SUBSIDIARY OR AFFILIATE OF VALOFE FROM ANY AND ALL
      LIABILITIES, OBLIGATIONS, CLAIMS, OR DEMANDS THAT MAY ARISE UNDER, FROM OR
      IN ANY WAY RELATE TO THESE TERMS, DIRECTLY OR INDIRECTLY, IN EXCESS OF AN
      ENTIRE AND AGGREGATE MAXIMUM AMOUNT WHOSE TOTAL SHALL NOT EXCEED THE
      AMOUNT PAID BY YOU IN CONNECTION WITH ONE ACCOUNT IN THE PRECEDING SIX (6)
      MONTHS. THE FOREGOING SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY LAW.
      THE FOREGOING ARE ESSENTIAL TERMS AND, IF ANY OF THESE TERMS ARE CONSTRUED
      AS UNENFORCEABLE, FAIL IN ESSENTIAL PURPOSE, OR BECOME VOID OR DETRIMENTAL
      TO VALOFE, ANY THIRD PARTIES UNDER AGREEMENT WITH VALOFE, ANY PARENT,
      SUBSIDIARY OR AFFILIATE OF VALOFE, THEN ANY LICENSE YOU MAY HAVE UNDER
      THESE TERMS SHALL BE TERMINATED IMMEDIATELY. MOREOVER, YOU ACKNOWLEDGE THE
      FOREGOING SHALL SURVIVE ANY EXPIRATION OR TERMINATION OF ANY LICENSE,
      AGREEMENT OR USE RELATED TO THE GAME.
      <br />
      <br />
      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
      DAMAGES, SO SOME OF THE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU.
      <br />
      <br />
      NOTHING IN THESE TERMS SHALL LIMIT VALOFE’S LIABILITY FOR DEATH OR
      PERSONAL INJURY RESULTING FROM ITS NEGLIGENCE OR FOR FRAUDULENT
      MISREPRESENTATION OR FOR ANY LIABILITY WHICH CANNOT BE EXCLUDED BY LAW.
      <br />
      <br />
      <br />
      <h2>11. INDEMNIFICATION</h2>
      <br />
      You agree to indemnify and hold harmless VALOFE, any third parties under
      agreement with VALOFE, any subsidiary or affiliate of VALOFE, from any
      claims, losses, damages, liabilities, including legal fees and expenses,
      arising out of (a) Your use or misuse of the Services, (b) any violation
      by You of VALOFE Policies, or (c) any breach of the representations,
      warranties, and covenants made by You herein, or (d) Your actions to
      knowingly affect the Services, the Game, content therein or Software via
      any bloatware, malware, computer virus, worm, Trojan horse, spyware,
      adware, crimeware, scareware, rootkit or any other program installed in a
      way that executable code of any program is scheduled to utilize or
      utilizes processor cycles during periods of time when such program is not
      directly or indirectly being used.
      <br />
      <br />
      <br />
      <h2>12. DISPUTE RESOLUTION AND GOVERNING LAW</h2>
      <br />
      (a) Informal Negotiations. To expedite resolution and control the cost of
      any dispute, controversy or claim related to this Agreement ("Dispute"),
      you and VALOFE agree to first attempt to negotiate any Dispute (except
      those Disputes expressly provided below) informally for at least thirty
      (30) days before initiating any arbitration or court proceeding. Such
      informal negotiations commence upon written notice from one person to the
      other. VALOFE will send its notice to your billing address and email you a
      copy to the email address you have provided to us. You will send your
      notice to support@valofe.com
      <br />
      <br />
      (c) Restrictions. You and VALOFE agree that any arbitration shall be
      limited to the Dispute between VALOFE and you individually. To the full
      extent permitted by law, (1) no arbitration shall be joined with any
      other; (2) there is no right or authority for any Dispute to be arbitrated
      on a class-action basis or to utilize class action procedures; and (3)
      there is no right or authority for any Dispute to be brought in a
      purported representative capacity on behalf of the general public or any
      other persons.
      <br />
      <br />
      (d) Exceptions to Informal Negotiations and Arbitration. You and VALOFE
      agree that the following Disputes are not subject to the above provisions
      concerning informal negotiations and binding arbitration: (1) any Disputes
      seeking to enforce or protect, or concerning the validity of, any of your
      or VALOFE ' intellectual property rights; (2) any Dispute related to, or
      arising from, allegations of theft, piracy, invasion of privacy or
      unauthorized use; and (3) any claim for injunctive relief.
      <br />
      <br />
      (e) Location. Any arbitration shall be initiated in the County of Los
      Angeles, State of California, United States of America. Any Dispute not
      subject to arbitration (other than claims proceeding in any small claims
      court), or where no election to arbitrate has been made, shall be decided
      by a court of competent jurisdiction within the 522B Innocentre, 72 Tat
      Chee Avenue, Kowloon Tong, HongKong SAR of China, and you and VALOFE agree
      to submit to the personal jurisdiction of that court.
      <br />
      <br />
      (f) Governing Law. Except as expressly provided otherwise, this Agreement
      shall be governed by, and will be construed under laws of the Republic of
      Korea and international law without regard to choice of law principles.
      (g) Severability. You and VALOFE agree that if any portion Section 23 is
      found illegal or unenforceable (except any portion of 23(d)), that portion
      shall be severed and the remainder of the Section shall be given full
      force and effect. If Section 23(d) is found to be illegal or unenforceable
      then neither you nor VALOFE will elect to arbitrate any Dispute falling
      within that portion of Section 23(d) found to be illegal or unenforceable
      and such Dispute shall be decided by a court of competent jurisdiction
      within the Republic of Korea and international law, and you and VALOFE
      agree to submit to the personal jurisdiction of that court.
      <br />
      <br />
      <br />
      <h2>13. MODIFICATIONS</h2>
      <br />
      It is Your responsibility to read, understand and accept these Terms and
      the VALOFE Policies in effect, every time You use the Services.
      <br />
      <br />
      WE MAY MODIFY THESE TERMS OF SERVICE AT ANY TIME BY POSTING THE SAME OR BY
      ANNOUNCEMENT ON OUR WEBSITE VALOFE.COM. YOUR CONTINUED USE OF THE GAME AND
      SERVICES FOLLOWING ANY MODIFICATIONS TO THESE TERMS WILL BE DEEMED
      ACCEPTANCE OF ANY SUCH MODIFICATIONS. IF YOU DO NOT ACCEPT THESE TERMS, DO
      NOT USE THE GAME OR SERVICES, OR DOWNLOAD OR USE ANY RELATED SOFTWARE.
      <br />
      <br />
      <br />
      <h2>14. GENERAL PROVISIONS</h2>
      <br />
      The failure of VALOFE or the Service Provider Customer to exercise its
      rights or insist upon the performance of the user's obligation hereunder
      shall not constitute a waiver or relinquishment of those or any other
      rights under these Terms of Agreement.
      <br /> <br />
      If any provision of the Terms of Agreement is found by a court of
      competent jurisdiction to be invalid, the parties nevertheless agree that
      the court should endeavor to give effect to the parties intentions as
      reflected in the provision and the other provisions of the Terms of
      Agreement shall continue to be binding on and remain in full force and
      effect.
      <br /> <br />
      The Terms of Agreement, together with any separate written or online
      agreement between you, VALOFE and/or the Service Provider Customer
      (including but not limited to the Mature Content Agreement if applicable
      to you) constitute the entire and only agreement, and supersedes any prior
      agreements between you, VALOFE and/or the Service Provider Customer,
      (including, but not limited to all prior versions of the User Agreement,
      General Terms of Use, and Terms of Agreement, where applicable).
      <br /> <br />
      The Terms of Agreement and the relationship between you, VALOFE and/or the
      Service Provider Customer shall be governed by and construed in accordance
      with the laws of the Republic of Korea and such parties agree to submit to
      the jurisdiction of such courts.
      <br /> <br />
      You agree that regardless of any statute or law to the contrary, any
      action, suit or proceeding arising out of or related to the use of the
      Service or to the Terms of Agreement must be commenced within 1 year after
      such claim or cause of action arose or be forever barred.
      <br /> <br />
      Section titles in the Terms of Agreement are for convenience only and have
      no legal or contractual effect or significance.
      <br /> <br />
      It is the express wish of the parties that the Terms of Agreement and all
      related documents have been drawn up in English.
    </MobileCustomerWrap>
  );
}

export default MobileTerms;
