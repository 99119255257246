import { isLogInState } from "atoms/userInfoAtom";
import useLoginCheck from "hooks/useLoginCheck";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

interface ProtectedRouteProps {
  children: React.ReactElement;
  loginRequired?: boolean; // 로그인 필요 여부
  guestOnly?: boolean; // 비로그인 유저만 접근 가능 여부
}

const OptionRoute: React.FC<ProtectedRouteProps> = ({
  children,
  loginRequired = false,
  guestOnly = false
}) => {
  const isLogin = useRecoilValue(isLogInState);
  const isNotLogin = useLoginCheck();
  const navigate = useNavigate();

  const getRetValue = () => {
    try {
      const urlObj = new URL(window.location.href); // URL 객체 생성
      return urlObj.searchParams.get("ret"); // 'ret' 파라미터 값 추출
    } catch (error) {
      console.error("유효하지 않은 URL입니다.", error);
      return null;
    }
  };

  // useEffect(() => {
  //   if (loginRequired && !isLogin) {
  //     navigate(`/?ret=${getRetValue()}`);
  //   }
  // }, [loginRequired, isLogin]);

  useEffect(() => {
    if (guestOnly && isLogin) {
      navigate("/");
    }
  }, [guestOnly, isLogin]);

  if (loginRequired && !isLogin) {
    isNotLogin();
    return null;
  }

  if (guestOnly && isLogin) {
    return null;
  }

  return children;
};

export default OptionRoute;
