import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

function MobileFaqWrap(props: { children: React.ReactNode }) {
  const navigate = useNavigate();
  return (
    <Wrap>
      <header id="mobile_onepage_header">
        <Link
          to={window.location.href}
          className="mobile_prev"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </Link>
      </header>
      <div id="wrapper">
        <div id="content">
          <div className="row-w">{props.children}</div>
        </div>
      </div>
      <footer id="mobile_onepage_footer" className="clearfix">
        <div className="row-w clearfix">
          <nav className="nav pull-right">
            <ul>
              {/* <li>
                <Link to={window.location.href} target="_blank">
                  Terms of Agreement
                </Link>
              </li>
              <li>
                <Link to={window.location.href} target="_blank">
                  Privacy Policy
                </Link>
              </li> */}
            </ul>
          </nav>
        </div>
      </footer>
    </Wrap>
  );
}

export default MobileFaqWrap;

const Wrap = styled.div`
  min-height: calc(100vh - 61px);
  overflow: hidden;
  margin: 0;
  background: #33363f;

  #mobile_onepage_header {
    /* position: relative; */
    position: fixed;
    z-index: 8888;
    clear: both;
    font-size: 16px;
    font-size: 1rem;
    width: 100%;
    height: 60px;
    text-align: left;
    background-color: #131313 !important;
    border-bottom: 1px solid #1e1d1d;
  }

  .mobile_prev {
    left: 20px;
    vertical-align: middle;
    position: absolute;
    top: 18.5px;
    font-weight: bold;
    color: #fff;
  }

  #mobile_onepage_footer {
    /* position: relative; */
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #242526;

    .row-w {
      overflow: visible;
    }

    a {
      color: #fff;
    }

    .nav {
      width: auto;
      margin-top: 8px;

      ul {
        list-style: none;
      }

      li {
        float: left;
        margin-left: 18px;
        font-size: 0.75rem;
      }
    }
  }

  section.inquiry {
    border-bottom: 4px solid #f39a00;
  }

  .sec1 {
    margin-bottom: 0;
    margin-top: calc(2rem + 61px);
    padding-bottom: 1rem;
    border-bottom: 4px solid #f39a00;

    li {
      display: list-item;
      float: left;

      &.on a {
        padding: 12px 16px;
        color: #f39a00;
        font-size: 1rem;
        font-weight: bold;
      }

      a {
        padding: 12px 16px;
        color: #868686;
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }

  p {
    color: #8e9297;
  }

  .sec2 {
    &.faq {
      margin-top: 0;
    }
    h1 {
      display: none;
    }

    .game-tit-tr {
      background: #f39a00;
      h4,
      .game-tit {
        color: #fff;
      }

      th {
        padding-top: 1rem;
      }

      td {
        padding: 1.25rem;
      }
    }
  }
  .faq .list li:hover {
    background: #efefef;
    .question h3 a {
      color: #f39a00;
    }
  }
  .faq .list li.on .question h3 a,
  .faq .list li.on .question h3.ellipsis,
  .faq .list li:hover .question h3,
  .faq .list li:hover .question h3 a,
  .faq li .question h3 a:active,
  .faq .list li .answer a {
    color: #f39a00;
  }

  .paging ul li.on a.num {
    color: #f39a00;
    border: 1px solid #f39a00;
  }

  .paging ul li a:hover {
    color: #f39a00;
    border: 1px solid #f39a00;
  }

  .sec3.inquiry .btn {
    background: #71a5b0;

    &:hover {
      background: #4c4c4c;
      color: #fff;
    }
  }

  .inquiry-send {
    .font-100 {
      margin-top: 2rem;
    }

    .wrap-agree {
      text-align: center;
      color: #8e9297;
      font-size: 1rem;
      margin-top: 1.5625rem;
    }

    .wrap-btn {
      margin-bottom: 8rem;
    }
  }

  .btn#btn-send,
  .btn#btn-inquiry {
    background: #71a5b0;

    &:hover {
      background-color: #4c4c4c;
    }
  }
`;
