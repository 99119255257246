import React from "react";
import "./CoinInfo.scoped.scss";
import { BILLING_CHARGE_URL, VFUN_IMAGE_DNS } from "utils/config/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { billingURL } from "utils/CommonFunc";
import { useRecoilValue } from "recoil";
import { isLoginStateSelector } from "atoms/userInfoAtom";
import { t } from "i18next";
import parse from "html-react-parser";
import { useCookies } from "react-cookie";
import useLoginCheck from "hooks/useLoginCheck";

function CoinInfo() {
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const [cookies] = useCookies();
  const serviceCode = cookies["service_code"];
  const isNotLogin = useLoginCheck();

  const goCoinPage = () => {
    if (isLoginState.isLogIn) {
      billingURL(
        BILLING_CHARGE_URL(serviceCode || "vfun", 1),
        "_self",
        isLoginState.isLauncher
      );
    } else {
      isNotLogin();
    }
  };

  return (
    <section className="gcoin-info" id="guideLink">
      <div className="row-w">
        <div className="clearfix">
          <img
            className="img-gcoin"
            src={`${VFUN_IMAGE_DNS}/web/vfun/images/common/gcoin/phone.png`}
            alt=" Buy easily and comfortably<br>Items wanted with G Coin."
          />
          <div className="desc">
            <span className="tit">{parse(t("g_coin_tit"))}</span>
            <button className="btn gcoin" onClick={goCoinPage}>
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ marginRight: "10px" }}
              />
              {t("g_coin_btn_purchase")}
            </button>
            <br />
            <strong className="define">{t("g_coin_is")} </strong>
            <p className="sentence">{parse(t("g_coin_desc"))}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CoinInfo;
