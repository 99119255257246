import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  eventPopUpDataState,
  preRegisterPopUpState,
  smsPopUpState
} from "atoms/uiAtom";
import "./PopNews.scoped.scss";
import parse from "html-react-parser";
import { useQuery } from "react-query";
import { IDetail, getDetail } from "api/libraryApi";
import { t } from "i18next";
import { headersState } from "atoms/userInfoAtom";
import LibraryDetailLoading from "components/loading/LibraryDetailLoading";
import { langCodeState } from "atoms/contentsAtom";
import PopUpWrap from "components/LibraryComponent/PopUp/PopUpWrap";
import PurpleBtn from "components/LibraryComponent/common/components/PurpleBtn";
import { usePlayGame } from "../common/hooks/usePlayGame";
import { useDefaultPreRegister } from "../common/hooks/useDefaultPreRegister";

function EventPopUp() {
  const [registerPopUp, setRegisterPopUp] = useRecoilState(
    preRegisterPopUpState
  );
  const eventPopUpData = useRecoilValue(eventPopUpDataState);
  const headers = useRecoilValue(headersState);
  const [loading, setLoading] = useState(true);
  const [preLoading, setPreLoading] = useState(false);
  const langCode = useRecoilValue(langCodeState);
  const setSmsPopUp = useSetRecoilState(smsPopUpState);
  const gamePlay = usePlayGame();
  const defaultPreRegister = useDefaultPreRegister();

  const { isLoading, data } = useQuery<IDetail>(
    ["detail", eventPopUpData.idx],
    () => getDetail(eventPopUpData.idx, headers, langCode)
  );

  useEffect(() => {
    const timeout = setTimeout(() => setLoading((loading) => !loading), 1000);
    return () => clearTimeout(timeout);
  }, [setLoading]);

  const goLauncher = async () => {
    gamePlay();

    setPreLoading(true);
    setTimeout(() => {
      setPreLoading(false);
    }, 1000);
  };

  const onClickBtn = () => {
    if (eventPopUpData.is_character === "Y") {
      goLauncher();
      return;
    }

    if (eventPopUpData.is_sms === "Y") {
      setRegisterPopUp(false);
      setSmsPopUp(true);
      return;
    }

    if (eventPopUpData.is_sms === "N") {
      defaultPreRegister();
    }
  };

  return (
    <PopUpWrap state={registerPopUp} setState={setRegisterPopUp}>
      {!isLoading ? (
        data?.result === 1 ? (
          loading ? (
            <LibraryDetailLoading />
          ) : (
            data.data !== null && (
              <>
                <div className="info">
                  <span className="cate">{t(data.data.lang_code)}</span>
                  <span className="bar" />
                  <span className="game-title">
                    {t(data.data.service_code)}
                  </span>
                </div>
                <div className="top-area clearix">
                  <div className="title ellipsis">{data.data.title}</div>
                  <div className="sub-area">
                    <span>{data.data.created_at}</span>
                    <PurpleBtn
                      title={t("join")}
                      onClick={onClickBtn}
                      isLoading={preLoading}
                      className="popup_pre_register"
                    />
                  </div>
                </div>

                <div className="cont">{parse(data.data.content)}</div>
                <PurpleBtn
                  title={t("join")}
                  onClick={onClickBtn}
                  isLoading={preLoading}
                  className="popup_pre_register"
                />
              </>
            )
          )
        ) : null
      ) : (
        <LibraryDetailLoading />
      )}
    </PopUpWrap>
  );
}

export default EventPopUp;
