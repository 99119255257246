import { t } from "i18next";
import React, { useState } from "react";
import { useQueries } from "react-query";
import { getMonthly, getWeekly, IRankingData } from "api/mainApi";
import PointRankingLoading from "components/loading/PointRankingLoading";

function PointRanking() {
  const [rankingTypeToggle, setRankingTypeToggle] = useState(false);

  const dataList = useQueries([
    {
      queryKey: ["weekly"],
      queryFn: getWeekly
    },
    {
      queryKey: ["monthly"],
      queryFn: getMonthly
    }
  ]);

  const isLoading = !rankingTypeToggle
    ? dataList[0].isLoading
    : dataList[1].isLoading;

  const data = !rankingTypeToggle ? dataList[0].data : dataList[1].data;

  return (
    <div className="clearfix Ranking">
      <div className="title-area">
        <h1>{t("point_ranking")}</h1>
      </div>
      <ul className="clearfix ranking-btn">
        <li onClick={() => setRankingTypeToggle(false)}>
          <button className={`week ${!rankingTypeToggle && "on"}`}>
            {t("stamp_weekly")}
          </button>
        </li>
        <li onClick={() => setRankingTypeToggle(true)}>
          <button className={`month ${rankingTypeToggle && "on"}`}>
            {t("stamp_monthly")}
          </button>
        </li>
      </ul>
      <ol className="rank-list clearfix">
        {!isLoading ? (
          data?.result === 1 ? (
            data.data.map((i: IRankingData) => (
              <li key={i.user_id}>
                <span className={`rank _${i.ranking}`}>{i.ranking}</span>
                <strong className="ellipsis">{i.user_id}</strong>
                <div className="pull-right piling">
                  <span className="blit">Points</span>
                  <span className="num">
                    {Number(i.point).toLocaleString()}
                  </span>
                </div>
              </li>
            ))
          ) : (
            <li>{t("no_data")}</li>
          )
        ) : (
          <PointRankingLoading />
        )}
      </ol>
    </div>
  );
}

export default PointRanking;
