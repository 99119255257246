import React from "react";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import parse from "html-react-parser";
import { t } from "i18next";

interface IGameTab {
  shortDesc: string;
  gameName: string;
  genre: string;
  gameImg: string;
  pre?: string;
}

function GameTab(props: IGameTab) {
  const { shortDesc, gameName, genre, gameImg, pre } = props;

  return (
    <GameWrapper className="clearfix">
      {/* 사진 영역 */}
      <div className="img-area">
        <img src={gameImg} alt="game-img" />
      </div>
      {/* 내용 영역 */}
      <div className="game-info-area">
        <div className="top">
          <h3>{gameName}</h3>
          <div className="genre">
            <FontAwesomeIcon icon={faDesktop} className="faDesktop" />
            {genre}
          </div>
        </div>
        <p>{parse(shortDesc || "")}</p>
        {pre === "Y" ? (
          <div className="event-pre">{t("pre_character_creation")}</div>
        ) : pre === "N" ? (
          <div className="event-pre">{t("pre_registration")}</div>
        ) : null}
      </div>
    </GameWrapper>
  );
}

export default GameTab;

const GameWrapper = styled.div`
  background: rgb(35 36 40);
  display: flex;
  align-items: center;
  padding: 1.25rem;

  .img-area {
    border-radius: 0.625rem;
    overflow: hidden;
    max-width: 6.75rem;
    max-height: 6.75rem;
    margin-right: 1.25rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .game-info-area {
    color: #fff;
    width: 100%;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }

    h3 {
      font-size: 1.125rem;
      letter-spacing: -0.1px;
      color: #fff;
      font-weight: 700;
    }

    p {
      font-size: 1rem;
      letter-spacing: -0.08px;
      font-weight: 400;
      max-height: 0.625rem;
      margin-bottom: 1.625rem;
      line-height: 1rem;
    }

    .genre {
      overflow: hidden;
      font-size: 0.75rem;
      color: #8e9297;
    }
    .faDesktop {
      margin-right: 4px;
    }
  }

  .event-pre {
    display: inline-block;
    padding: 8px 1.25rem;
    border-radius: 1.25rem;
    font-size: 0.875rem;
    background: #5f778d;
    letter-spacing: -0.07px;

    :hover {
      background: #1a5ea7;
    }
  }

  @media screen and (max-width: 1200px) {
    .game-info-area h3 {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 460px) {
    .game-info-area .genre {
      display: none;
    }
  }

  @media screen and (max-width: 380px) {
    .game-info-area {
      h3 {
        font-size: 0.875rem;
      }

      p {
        font-size: 0.75rem;
      }
    }
  }
`;
