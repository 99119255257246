import axios from "axios";
import { BASE_PATH } from "./api";

//// header slide (헤더 슬라이드)
export interface IHeaderData {
  image_url: string;
  link_url: string;
  logo_url: string;
  target: string;
  title: string;
  idx: number;
  game_title: string;
}

export interface IHeaderSlide {
  data: IHeaderData[];
  result: number;
}

export const getHeaderSlide = (langCode: string) =>
  axios
    .get(`${BASE_PATH}/api/vfun/featured?lang=${langCode}`)
    .then((res) => res.data);

//// new games / recommend, coming
export interface IDetailData {
  desc: string;
  genre: string;
  thumbnail_url: string;
  idx: number;
  link_url: string;
  service_code: string;
  target: string;
  title: string;
  type: number;
  pre_creation?: string;
}

interface INewData {
  commingsoon_count: number;
  commingsoon_data: IDetailData[];
  recommend_count: number;
  recommend_data: IDetailData[];
}

export interface INewGames {
  data: INewData;
  result: number;
}

export const getNewGames = (langCode: string) =>
  axios
    .get(`${BASE_PATH}/api/vfun/commingsoon?lang=${langCode}`)
    .then((res) => res.data);

//// point ranking
export interface IRankingData {
  point: string;
  user_id: string;
  ranking: number;
}

interface IRanking {
  data: IRankingData[];
  result: number;
}

// weekly
export const getWeekly = () =>
  axios.get(`${BASE_PATH}/api/vfun/weeklypointranking`).then((res) => res.data);

// monthly
export const getMonthly = () =>
  axios
    .get(`${BASE_PATH}/api/vfun/monthlypointranking`)
    .then((res) => res.data);

//// video slide
interface IVideoData {
  published_at: string;
  video_thumnail_medium: string;
  video_title: string;
  video_url: string;
}

export interface IVideo {
  data: IVideoData[];
  result: number;
}

export const getVideo = () =>
  axios.get(`${BASE_PATH}/api/vfun/videolist`).then((res) => res.data);

//// event
export interface ICommunityData {
  description: string;
  game_name: string;
  icon_url: string;
  pubDate: string;
  title: string;
  url: string;
  img_url: string | null;
}

export interface ICommunity {
  data: ICommunityData[];
  result: number;
}

export const getEvent = (langCode: string) =>
  axios
    .get(`${BASE_PATH}/api/vfun/eventlist?lang=${langCode}`)
    .then((res) => res.data);

//// news
export const getNews = (langCode: string) =>
  axios
    .get(`${BASE_PATH}/api/vfun/newslist?lang=${langCode}`)
    .then((res) => res.data);
