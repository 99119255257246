import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { newsDataIdxState, popUpNewsState } from "atoms/uiAtom";
import "./PopNews.scoped.scss";
import parse from "html-react-parser";
import { useQuery } from "react-query";
import { IDetail, getDetail } from "api/libraryApi";
import { t } from "i18next";
import { headersState } from "atoms/userInfoAtom";
import LibraryDetailLoading from "components/loading/LibraryDetailLoading";
import { langCodeState } from "atoms/contentsAtom";
import SocialBtn from "components/LibraryComponent/PopUp/SocialBtn";
import PopUpWrap from "components/LibraryComponent/PopUp/PopUpWrap";

function PopNews() {
  const [popUpNews, setPopUpNews] = useRecoilState(popUpNewsState);
  const newsDataIdx = useRecoilValue(newsDataIdxState);
  const headers = useRecoilValue(headersState);
  const [loading, setLoading] = useState(true);
  const langCode = useRecoilValue(langCodeState);

  const { isLoading, data } = useQuery<IDetail>(["detail", newsDataIdx], () =>
    getDetail(newsDataIdx, headers, langCode)
  );

  useEffect(() => {
    const timeout = setTimeout(() => setLoading((loading) => !loading), 1000);
    return () => clearTimeout(timeout);
  }, [setLoading]);

  return (
    <PopUpWrap state={popUpNews} setState={setPopUpNews}>
      {!isLoading ? (
        data?.result === 1 ? (
          loading ? (
            <LibraryDetailLoading />
          ) : (
            data.data !== null && (
              <>
                <div className="info">
                  <span className="cate">{t(data.data.lang_code)}</span>
                  <span className="bar" />
                  <span className="game-title">{t(data.data.game_title)}</span>
                </div>
                <div className="top-area clearix">
                  <div className="title">{data.data.title}</div>
                  <div className="sub-area">
                    <span>{data.data.created_at}</span>
                    <SocialBtn
                      lang_code={data.data.section_code}
                      idx={data.data.idx}
                      like_count={data.data.like_count}
                      user_like_status={data.data.user_like_status}
                      addClassName="top-btn"
                    />
                  </div>
                </div>

                <div className="cont">{parse(data.data.content)}</div>
              </>
            )
          )
        ) : null
      ) : (
        <LibraryDetailLoading />
      )}
    </PopUpWrap>
  );
}

export default PopNews;
