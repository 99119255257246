import React from "react";
// import "language/i18n";
import HeaderSlider from "components/MainComponent/HeaderSlider/HeaderSlider";
import AllGameScroll from "components/MainComponent/AllGameScroll";
import HottestGame from "components/MainComponent/HottestGame/HottestGame";
import VideosSlider from "components/MainComponent/MultipleSliders/VideosSlider";
import CoinInfo from "components/MainComponent/CoinInfo/CoinInfo";
import AllGamesTab from "components/MainComponent/AllGames/AllGamesTab";
import NewGames from "components/MainComponent/NewGame/NewGames";
import Container from "components/common/Container";
import { popUpState, videoPopUpState } from "atoms/uiAtom";
import { useRecoilValue } from "recoil";
import VideoPopUp from "components/common/PopUp/VideoPopUp";
import ShopPopUp from "components/common/PopUp/ShopPopUp";
import { isLogInState, userCountryState } from "atoms/userInfoAtom";
import LoginPopUp from "components/common/PopUp/LoginPopUp";
import EnoughPopUp from "components/common/PopUp/EnoughPopUp";
import VfunHelmet from "utils/VfunHelmet";
import LoginHistoryPopUp from "components/common/PopUp/LoginHistoryPopUp";

function Main() {
  const isLogin = useRecoilValue(isLogInState);
  const videoPopUp = useRecoilValue(videoPopUpState);
  const pointPopUpMain = useRecoilValue(popUpState("main"));
  const enoughPopUp = useRecoilValue(popUpState("enough"));
  const historyPopUp = useRecoilValue(popUpState("history"));
  const userCountry = useRecoilValue(userCountryState);

  return (
    <Container background="">
      <VfunHelmet title="Want fun? VFUN!" />
      <HeaderSlider />
      <AllGameScroll />
      <HottestGame />
      <NewGames />
      {/* {userCountry === "CN" ? null : <VideosSlider />} */}
      {/* <PointSlider /> */}
      <AllGamesTab />
      {/* <EventNews /> */}
      <CoinInfo />
      {videoPopUp && <VideoPopUp />}
      {historyPopUp && <LoginHistoryPopUp />}
      {pointPopUpMain && isLogin ? <ShopPopUp /> : <LoginPopUp />}
      {enoughPopUp && <EnoughPopUp />}
    </Container>
  );
}

export default Main;
