import { t } from "i18next";
import MobileInquiryForm from "components/SupportComponent/inquiry/MobileInquiryForm";
import MobileFaqWrap from "components/common/Wrap/MobileFaqWrap";

function MobileInquiry() {
  return (
    <MobileFaqWrap>
      <section className="sec1 tit row-w">
        <h1 className="tit-h1">{t("ask_a_question")}</h1>
        <p>
          {t("my_inquiry_desc1")}
          <br />
          {t("my_inquiry_desc2")}
          <br />
          {t("my_inquiry_desc3")}
        </p>
      </section>
      <MobileInquiryForm />
    </MobileFaqWrap>
  );
}

export default MobileInquiry;
