import ContentLoader from "react-content-loader";

function FourListLoading() {
  return (
    <ContentLoader
      speed={2} // 속도
      // width={530}
      // height={180}
      viewBox="0 0 500 140"
      backgroundColor="#27292d" // 배경색
      foregroundColor="#4b4b4b" // 요소 색
    >
      <rect x="0" y="0" rx="6" ry="6" width="49%" height="60" />
      <rect x="51%" y="0" rx="6" ry="6" width="49%" height="60" />
      <rect x="0" y="70" rx="6" ry="6" width="49%" height="60" />
      <rect x="51%" y="70" rx="6" ry="6" width="49%" height="60" />
    </ContentLoader>
  );
}

export default FourListLoading;
