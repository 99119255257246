import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import GameList from "components/OTPComponent/GameList";
import GoogleOTPWrap from "components/OTPComponent/GoogleOTPWrap";
import { t } from "i18next";
import { emailAuthStatus } from "atoms/userInfoAtom";
import { useRecoilValue } from "recoil";
import parse from "html-react-parser";

function OTPComplete() {
  const emailAuth = useRecoilValue(emailAuthStatus);
  const navigate = useNavigate();

  useEffect(() => {
    if (emailAuth !== "Y") {
      navigate("/membership/sns_email_auth_confirm");
    }
  }, []);

  if (emailAuth !== "Y") {
    return null;
  }

  return (
    <GoogleOTPWrap>
      <section className="sub-cont mt0">
        <div className="row-w">
          <article className="mt20">
            <h2 className="text-center">
              <span className="step">Step. 4</span>
              <br />
              <span className="bar" />
              <br />
              <span>{t("g_otp_step4_tit")}</span>
            </h2>
            <div className="box step1 text-center">
              <p className="message">
                {parse(t("g_otp_step4_message"))}
                <br />
              </p>
              <GameList />
            </div>
            <div className="wrap-btn mb45">
              <Link to="/membership/myinfo" className="btn round-type long">
                {t("confirm")}
              </Link>
            </div>
          </article>
        </div>
      </section>
    </GoogleOTPWrap>
  );
}

export default OTPComplete;
