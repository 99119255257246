import React from "react";
import ContentLoader from "react-content-loader";

function PointRankingLoading() {
  return (
    <ContentLoader
      speed={2} // 속도
      // width={300}
      // height={100}
      viewBox="0 0 500 300"
      backgroundColor="#27292d" // 배경색
      foregroundColor="#4b4b4b" // 요소 색
    >
      <circle cx="30" cy="30" r="25" />
      <rect x="70" y="10" rx="10" ry="10" width="400" height="40" />

      <circle cx="30" cy="100" r="25" />
      <rect x="70" y="80" rx="10" ry="10" width="400" height="40" />

      <circle cx="30" cy="170" r="25" />
      <rect x="70" y="150" rx="10" ry="10" width="400" height="40" />
    </ContentLoader>
  );
}

export default PointRankingLoading;
