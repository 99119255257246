import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { VFUN_IMAGE_DNS } from "utils/config/Config";

interface IProps {
  point: string;
  num: string;
  type?: string;
}

function InstallBox(props: IProps) {
  const { t } = useTranslation();
  const { point, num, type } = props;
  const changeEdge = () => {
    window.open("microsoft-edge:" + window.location, "_self");
  };
  const downLoadChrome = () => {
    window.open("//www.google.com/intl/en/chrome/", "_blank");
  };

  return (
    <div className="install-box" id={num}>
      <div className="title">{t(`Windows 10 ${point} 유저`)}</div>
      <div className="box">
        <Link className="go-btn" to="#" onClick={downLoadChrome}>
          <img
            src={
              VFUN_IMAGE_DNS +
              "/web/vfun/images/vfun_lounge/images/icon-chrome.png"
            }
            alt="icon-chrome"
          />
          <span>{t("Chrome 설치하기")}</span>
          <img
            src={
              VFUN_IMAGE_DNS +
              "/web/vfun/images/vfun_lounge/images/icon-down.png"
            }
            alt="icon-down"
          />
        </Link>
        {type === "edge" ? (
          <Link className="go-btn" to="#" onClick={changeEdge}>
            <img
              src={
                VFUN_IMAGE_DNS +
                "/web/vfun/images/vfun_lounge/images/icon-edge.png"
              }
              alt="icon-edge"
            />
            <span>{t("Edge로 사이트 열기")}</span>
          </Link>
        ) : null}
        <p>
          {t("VFUN 라운지는 Chrome 또는 Edge에 최적화 되어 있습니다.")} <br />
          {t(
            "크롬을 설치하시면 익스플로러보다 쾌적한 환경에서 라운지 이용이 가능합니다."
          )}
        </p>
      </div>
    </div>
  );
}

export default InstallBox;
