import React from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { getNewGames, INewGames } from "api/mainApi";
import { langCodeState } from "atoms/contentsAtom";
import CommingGame from "./CommingGame";
import RecomendedGame from "./RecommendedGame";
import NewGameLoading from "components/loading/NewGameLoading";
import { SlideArrow } from "components/common/SlideArrow";

function NewGames() {
  const langCode = useRecoilValue(langCodeState);
  const { isLoading, data } = useQuery<INewGames>(["new-games", langCode], () =>
    getNewGames(langCode)
  );

  return !isLoading ? (
    data?.result === 1 ? (
      data.data.commingsoon_count === 0 &&
      data.data.recommend_count === 0 ? null : (
        <SlideArrow className="main-cont" id="commingGame">
          <div className="row-w clearfix mg">
            {/* recomend */}
            <RecomendedGame data={data} />
            {/* comming */}
            <CommingGame data={data} />
          </div>
        </SlideArrow>
      )
    ) : null
  ) : (
    <NewGameLoading />
  );
}

export default NewGames;
