import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import PopUpWrap from "components/common/PopUpWrap";
import { popUpState } from "atoms/uiAtom";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { itemDataState, langCodeState } from "atoms/contentsAtom";
import {
  ICharacter,
  IPointList,
  IServer,
  getCharacterlist,
  getServerlist,
  purchaseApi,
  purchaseLogApi
} from "api/api";
import { headersState, userPointState } from "atoms/userInfoAtom";
import axios from "axios";
import { useQuery } from "react-query";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { deletetag } from "utils/CommonFunc";
import customAlert from "../Alert/customAlert";
import customConfirm from "../Alert/customConfirm";

function EnoughPopUp() {
  const [enoughPopUp, setEnoughPopUp] = useRecoilState(popUpState("enough"));
  const itemData: IPointList = useRecoilValue(itemDataState);
  const [count, setCount] = useState(1);
  const [totalAmount, setTotalAmount] = useState(itemData.product_price);
  const [server, setServer] = useState("");
  const [character, setCharacter] = useState("");
  const userPoint = useRecoilValue(userPointState);
  const headers = useRecoilValue(headersState);
  const langCode = useRecoilValue(langCodeState);
  const [inActive, setInActive] = useState(true);

  const onSelectServer = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setServer(e.target.value);
  };

  const onSelecteCharacter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCharacter(e.target.value);
  };

  const { isLoading: serverLoading, data: serverData } = useQuery<IServer>(
    ["serverList"],
    () => getServerlist(itemData.service_code),
    {
      enabled: itemData.service_code === "icarusm_global"
    }
  );

  const { isLoading: characterLoading, data: characterData } =
    useQuery<ICharacter>(
      ["characterList", server],
      () => getCharacterlist(itemData.service_code, server, headers),
      {
        enabled: server !== ""
      }
    );

  const onClickClose = () => {
    setEnoughPopUp(false);
  };

  const onClickUp = () => {
    if (userPoint <= totalAmount + itemData.product_price) return;
    setCount((prev) => prev + 1);
  };

  const onClickDown = () => {
    setCount((prev) => prev - 1);
    if (count <= 1) {
      setCount(1);
    }
  };

  useEffect(() => {
    setTotalAmount(itemData.product_price * count);
  }, [count]);

  const onClickConfirm = async () => {
    try {
      setInActive(false);
      let resLog = await axios.post(
        purchaseLogApi,
        {
          idx: itemData.idx,
          product_amount: count
        },
        {
          headers: headers
        }
      );
      if (resLog.data.result === 1) {
        if (resLog.data.data === null || resLog.data.data === undefined) {
          customAlert(t("my_inquiry_desc13"));
        }
        if (await customConfirm(t("would_you_like_to_purchase_that_item"))) {
          try {
            let res = await axios.post(
              purchaseApi(itemData.service_code),
              {
                product_idx: Number(itemData.product_no),
                total_amount: count,
                total_price: totalAmount,
                world_key: server || "",
                char_name: character || "",
                char_key: "",
                lang: langCode,
                row_idx: resLog.data.data
              },
              {
                headers: headers
              }
            );
            if (res.data.result === 1) {
              customAlert(deletetag(t("shop_point_buy_ok"), []) as string);
              setEnoughPopUp(false);
            } else if (res.data.result === -97) {
              customAlert(parse(t("shop_point_error")) as string);
            } else if (res.data.result === -96) {
              customAlert(parse(t("send_verification_fail3")) as string);
            } else {
              customAlert(parse(t("shop_point_buy_ingame_error")) as string);
            }
          } catch (error) {
            console.log("구매", error);
          }
        } else {
          customAlert(parse(t("shop_point_buy_ingame_error")) as string);
        }
      } else {
        customAlert(t("my_inquiry_desc13"));
      }
    } catch (error) {
      console.log("log", error);
    } finally {
      setInActive(true);
    }
  };

  return (
    <PopUpWrap close={onClickClose} popUp={enoughPopUp}>
      <div className="alert" id="alertMsg">
        <h6 className="alert-text">
          <div className="desc-basic clearfix">
            <img className="item-desc-img" src={itemData.thumbnail_url} />
            <ul className="item-desc-basic">
              <li>
                <span className="game-name">{itemData.service_code}</span>
                {itemData.product_name}
              </li>
              <li>
                <b>P</b>
                <span id="product_price">
                  {itemData.product_price.toLocaleString()}
                </span>
              </li>
            </ul>
          </div>
        </h6>
        <p className="p-desc">{t("shop_price_amount_msg")}</p>
        <table className="tbl-purchase">
          <tbody>
            <tr>
              <th>{t("shop_price_amount")}</th>
              <td>
                <input
                  id="total_amount"
                  type="text"
                  className="inputPrice"
                  value={count}
                  readOnly={true}
                />
                <Link
                  to={window.location.href}
                  className="arrow after"
                  onClick={onClickUp}
                >
                  ▲
                </Link>
                <Link
                  to={window.location.href}
                  className="arrow before"
                  onClick={onClickDown}
                >
                  ▼
                </Link>
              </td>
            </tr>
            <tr>
              <th>{t("shop_subtract_point")}</th>
              <td>
                <input
                  type="text"
                  className="inputPrice"
                  id="total_price"
                  value={totalAmount.toLocaleString()}
                  readOnly={true}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {itemData.service_code === "icarusm_global" ? (
          <>
            <p className="p-desc">Please select a character.</p>
            <table className="tbl-purchase">
              <tbody>
                <tr>
                  <th>Server</th>
                  <td>
                    <label htmlFor="world_key" className="hidden"></label>
                    <select id="world_key" onChange={onSelectServer}>
                      <option className="short" value="-99">
                        Select server
                      </option>
                      {!serverLoading
                        ? serverData?.result === 1
                          ? serverData.data?.worldInfo.map((i) => (
                              <option
                                className="short"
                                value={i.WorldNo}
                                key={i.WorldNo}
                              >
                                {i.WorldName}
                              </option>
                            ))
                          : null
                        : null}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>Character</th>
                  <td>
                    <label htmlFor="char_key" className="hidden"></label>
                    {characterLoading ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        pulse
                        className="loading"
                      />
                    ) : null}

                    <select id="char_key" onChange={onSelecteCharacter}>
                      <option className="short" data-limit="22">
                        Select character
                      </option>
                      {!characterLoading
                        ? characterData?.result === 1 &&
                          characterData.data.length !== 0
                          ? characterData.data.map((i: any) => (
                              <option className="short" value={i}>
                                {i}
                              </option>
                            ))
                          : null
                        : null}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : null}
      </div>
      <div className="btn-wrap clearfix" id="alertBtn">
        <Link
          to={window.location.href}
          className="btn-alert-line"
          onClick={onClickClose}
        >
          {t("cancel")}
        </Link>
        <Link
          to={window.location.href}
          className={`btn-alert ${inActive ? "" : "not"}`}
          id="confirm_btn"
          onClick={onClickConfirm}
        >
          {t("confirm")}
        </Link>
      </div>
    </PopUpWrap>
  );
}

export default EnoughPopUp;
