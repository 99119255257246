import Container from "components/common/Container";
import PageNav from "components/common/PageNav/PageNav";
import "./GoogleOTPWrap.scoped.scss";
import OTPInfo from "./OTPInfo";

function GoogleOTPWrap(props: { children: React.ReactNode }) {
  return (
    <Container background="#33363f">
      <PageNav title="Setting Google OTP" type="" />
      <div id="google_otp">
        <section className="sub-top otp">
          <div className="row-w clearfix">
            <span className="icon otp"></span>
            <p className="ph">
              The Google verification code is a one-time code that can be
              retrieved from the{" "}
              <strong className="color-blue-light">Google Authenticator</strong>{" "}
              App.
              <br />
              This code is needed once you activate the Two-Step Verification
              Service in order{" "}
              <strong className="color-white">
                to secure your game accounts.
              </strong>
              <br />
              This is a free service from Google and we highly recommend you
              enable the Two-Step Verification Service.{" "}
            </p>
          </div>
        </section>
        {props.children}
        <OTPInfo />
      </div>
    </Container>
  );
}

export default GoogleOTPWrap;
